<template>
    <Navbar />
    <section id="privacy-policy" class="privacy-policy">
        <div class="container">
            <h1 class="header">Privacy Policy</h1>
            <p>This Privacy Policy describes how trinique.in (the “Site” or “we”) collects, uses, and discloses your Personal Information when you visit or make a purchase from the Site.</p>
            <h3>Collecting Personal Information</h3>
            <p>When you visit the Site, we collect certain information about your device, your interaction with the Site, and information necessary to process your purchases. We may also collect additional information if you contact us for customer support. In this Privacy Policy, we refer to any information that can uniquely identify an individual (including the information below) as “Personal Information”. See the list below for more information about what Personal Information we collect and why.</p>
            <p><strong>Device information</strong></p>
            <ul>
                <li> Examples of Personal Information collected: version of web browser, IP address, time zone, cookie information, what sites or products you view, search terms, and how you interact with the Site.</li>
                <li>Purpose of collection: to load the Site accurately for you, and to perform analytics on Site usage to optimize our Site.</li>
                <li>Source of collection: Collected automatically when you access our Site using cookies, log files, web beacons, tags, or pixels [ADD OR SUBTRACT ANY OTHER TRACKING TECHNOLOGIES USED].</li>
            </ul>
            <p><strong>Order information</strong></p>
            <ul>
                <li>Examples of Personal Information collected: name, billing address, shipping address, payment information (including credit card numbers [INSERT ANY OTHER PAYMENT TYPES ACCEPTED]), email address, and phone number.</li>
                <li>Purpose of collection: to provide products or services to you to fulfill our contract, to process your payment information, arrange for shipping, and provide you with invoices and/or order confirmations, communicate with you, screen our orders for potential risk or fraud, and when in line with the preferences you have shared with us, provide you with information or advertising relating to our products or services.</li>
                <li>Source of collection: collected from you.</li>
            </ul>
            <p><strong>Customer support information</strong></p>
            <ul>
                <li> Examples of Personal Information collected: [MODIFICATIONS TO THE INFORMATION LISTED ABOVE OR ADDITIONAL INFORMATION AS NEEDED]</li>
                <li>Purpose of collection: to provide customer support.</li>
                <li>Source of collection: collected from you.</li>
            </ul>
            <h3>MINORS</h3>
            <p>The Site is not intended for individuals under the minimum age according to your state or providence and residence. We do not intentionally collect Personal Information from children. If you are the parent or guardian and believe your child has provided us with Personal Information, please contact us at the address below to request deletion.</p>
            <h3>Sharing Personal Information</h3>
            <p>We share your Personal Information with service providers to help us provide our services and fulfill our contracts with you, as described above. For example:</p>
            <ul>
                <li>We may share your Personal Information to comply with applicable laws and regulations, to respond to a subpoena, search warrant or other lawful request for information we receive, or to otherwise protect our rights.</li>
                <li>We may share your personal information to comply with our advertisements and target marketing.</li>
            </ul>
        </div>
    </section>
    <Footer />
</template>

<script>
export default {
    name: 'privacyPolicy',
    
}
</script>

<style scoped>
#privacy-policy {
    background: var(--bg-color);
}

#privacy-policy .container {
    position: relative;
    padding: 74px 13px;
}

#privacy-policy .header {
    text-align: center;
}
</style>