<template>
    <section id="allOrders" class="allOrders">
        <div class="container mt-5 pt-5">
            <h3 class="d-inline-block mb-1 align-middle">Orders List</h3>
            <div :key="index" v-for="(profile,index) in details" class="container details">
                <div class="mb-3 mt-2">
                    <p class="">
                        <strong>Name : {{profile.data().name}}</strong>
                        <span @click="deleteProfile(profile.id)" class="btn btn-danger float-right">Delete Profile</span>
                    </p>
                    <p class=""><strong>Address : {{profile.data().address}}</strong></p>
                    <p class=""><strong>Phone Number : {{profile.data().phone}}</strong></p>
                </div>
                
                <div class="table-responsive">
                    <table class="table table-hover">
                        <thead>
                            <tr>
                                <th scope="col" class="border-0 bg-light">
                                    <div class="p-2 text-uppercase">Order Date</div>
                                </th>
                                <th scope="col" class="border-0 bg-light">
                                    <div class="p-2 text-uppercase">Order ID</div>
                                </th>
                                <th scope="col" class="border-0 bg-light">
                                    <div class="py-2 px-3 text-uppercase">Items</div>
                                </th>
                                <th scope="col" class="border-0 bg-light">
                                    <div class="py-2 text-uppercase">Total Price</div>
                                </th>
                                <th scope="col" class="border-0 bg-light">
                                    <div class="py-2 text-uppercase">Modify</div>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr :key="index" v-for="(order,index) in profile.data().orders">
                                <td class="border-0 align-middle">{{order.date}}</td>
                                <td class="border-0 align-middle">{{order.orderId}}</td>
                                <td class="border-0 align-middle">
                                    <ul>
                                        <li :key="item.index" v-for="item in order.items">
                                            <div>
                                                <p>{{item.productName}} (C - {{item.productColor}},S - {{item.productSize}},Q - {{item.productQuantity}})</p>
                                            </div>
                                        </li>
                                    </ul>
                                </td>
                                <td class="border-0 align-middle"><strong>&#8377;{{order.totalPrice}}.00</strong></td>
                                <td class="border-0 align-middle">
                                    <button @click="deleteOrder(profile.id,index)" class="btn btn-danger p-1">Delete</button>
                                    <button @click="editStatus(profile.id,order.orderId)" class="btn btn-success p-1">Update Status</button>
                                    <button @click="sendWhatsapp(order.date,order.deliveryDate,order.orderId,order.totalPrice,profile.data().name,profile.data().phone)" class="btn btn-primary p-1">Whatsapp Msg</button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <hr>
                </div> 
            </div>

            <!-- Order status edit modal -->
            <div class="modal fade" id="order-status" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel">Update order status</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <div class="form-group">
                                <input type="radio" id="a" value="ordered" v-model="status">
                                <label for="a"> Ordered</label>
                                <br>
                                <input type="radio" id="b" value="shipped" v-model="status">
                                <label for="b"> Shipped</label>
                                <br>
                                <input type="radio" id="c" value="delivered" v-model="status">
                                <label for="c"> Delivered</label>
                                <br>
                                <input type="radio" id="d" value="cancelled" v-model="status">
                                <label for="d"> Cancelled</label>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button @click="updateStatus" type="button" class="btn btn-primary">Update Status</button>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Order status edit modal -->
        </div>
        
    </section>
</template>

<script>
import {db} from '../../firebase'

export default {
    name: 'AllOrders',
    data(){
        return{
            details: [],
            status: null,
            profileId: null,
            OrderId: null,
        }
    },
    methods: {
        sendWhatsapp(date,delDate,orderId,totalPrice,name,phone){
            var link = `https://trinique.shiprocket.co/tracking/order/${orderId}`
            var text = `*Hello ${name}*, \nThank you for your order placed on ${date}. Your Order ID is ${orderId}. Total amount to be paid is Rs. ${totalPrice}/- (incl. of all taxes). Expected Delivery date is ${delDate}.\nYou can track your order here: ${link}\n\nRegards,\nTeam Trinique`
            text = encodeURI(text)
            
            window.open(`https://api.whatsapp.com/send?phone=+91${phone}&text=${text}`,'_blank')
        },
        watcher(){
            db.collection('orders').onSnapshot((querySnapshot) => {
                this.details = []
                querySnapshot.forEach((doc) => {
                    this.details.push(doc)
                })
            })
        },
        editStatus(profileId,orderId){
            db.collection("profiles").doc(profileId).collection("orders").doc(orderId).get()
                .then((querySnapshot) => {
                    this.status = querySnapshot.data().status.toLowerCase()
                    window.$('#order-status').modal("show")
                }).catch((error) => {
                    console.log(error)
                })
            this.profileId = profileId
            this.orderId = orderId
        },
        updateStatus(){
            db.collection("profiles").doc(this.profileId).collection("orders").doc(this.orderId).update({status: this.status})
                .then(() => {
                    window.Toast.fire({
                        icon: 'success',
                        title: 'Updated Successfully'
                    })
                    window.$('#order-status').modal("hide")
                }).catch((error) => {
                    console.log(error)
                })
        },
        deleteProfile(id){
            window.Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
            }).then((result) => {
                if(result.value){
                    db.collection('orders').doc(id).delete()
                        .then(() => {
                            this.watcher()
                            window.Toast.fire({
                                icon: 'success',
                                title: 'Profile Deleted Successfully'
                            })
                        }).catch((error) => {
                            console.log(error)
                        })
                }
            })
        },
        deleteOrder(id,index){
            window.Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
            }).then((result) => {
                if(result.value){
                    db.collection('orders').doc(id).get()
                        .then((querySnapshot) => {
                            const profile = querySnapshot.data()
                            profile.orders.splice(index,1)
                            this.updateOrder(id,profile)
                        }).catch((error) => {
                            console.log(error)
                        })
                }
            })
        },
        updateOrder(id,profile){
            db.collection('orders').doc(id).update(profile)
                .then(() => {
                    this.watcher()
                    window.Toast.fire({
                        icon: 'success',
                        title: 'Order Deleted Successfully'
                    })
                }).catch((error) => {
                    console.log(error)
                })
        },
        getOrders(){
            db.collection('orders').get()
                .then((querySnapshot) => {
                    querySnapshot.forEach(doc => {
                        this.details.push(doc)
                    })
                }).catch((error) => {
                    console.log(error)
                })
        },
    },
    created(){
        this.getOrders()
    }

}
</script>

<style scoped>
#allOrders .details {
    text-align: left;
}
</style>