<template>
    <section id="team" class="team">
      <div class="container" data-aos="fade-up">

        <div class="section-title">
          <p>Meet our Team</p>
        </div>

        <div class="row justify-content-center">

          <div :key="index" v-for="(member,index) in members" class="col-lg-3 col-md-4 d-flex align-items-stretch outer-box">
            <div class="member" data-aos="fade-up" data-aos-delay="100">
              <div class="member-img">
                <img :src="member.image" class="img-fluid" alt="">
                <div class="social">
                  <a :href="member.fbLink" target="_blank"><i class="fa fa-facebook"></i></a>
                  <a :href="member.igLink" target="_blank"><i class="fa fa-instagram"></i></a>
                </div>
              </div>
              <div class="member-info">
                <h4>{{member.name}}</h4>
                <span>{{member.designation}}</span>
              </div>
            </div>
          </div>
        </div>

      </div>
    </section>
</template>

<script>
export default {
    name: 'ourteam',
    data(){
      return{
        members: [
          {
            name: "Ishaan Goel",
            designation: "Web Designer",
            image: "img/team/team-1.jpeg",
            fbLink: "https://www.facebook.com/ishaangoel184/",
            igLink: "https://www.instagram.com/ishaangoel12/"
          },
          {
            name: "Aditya Sachdeva",
            designation: "Content Manager",
            image: "img/team/team-2.jpeg",
            fbLink: "https://www.facebook.com/aditya.sachdeva.9634",
            igLink: "https://www.instagram.com/ad.itya9309/"
          },
          {
            name: "Saurabh Mittal",
            designation: "Product Manager",
            image: "img/team/team-3.jpeg",
            fbLink: "https://www.facebook.com/saurabh.mittal.395",
            igLink: "https://www.instagram.com/saurabhmittal31/"
          },
        ]
      }
    }

}
</script>

<style scoped>
.team {
  background: var(--bg-color);
  padding: 30px 0;
}

.section-title {
  padding-bottom: 40px;
  text-align: center;
}

.section-title p {
  margin: 0;
  margin: 0;
  font-size: 36px;
  font-weight: 700;
  text-transform: uppercase;
  color: #151515;
}

.team .outer-box {
    margin: 0 30px;
}

.team .member {
  margin-bottom: 20px;
  overflow: hidden;
  border-radius: 5px;
  background: white;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
}

.team .member .member-img {
  position: relative;
  overflow: hidden;
}

.team .member .social {
  position: absolute;
  left: 0;
  bottom: 30px;
  right: 0;
  opacity: 0;
  transition: ease-in-out 0.3s;
  text-align: center;
}

.team .member .social a {
  transition: color 0.3s;
  color: #151515;
  margin: 0 3px;
  padding-top: 7px;
  border-radius: 4px;
  width: 36px;
  height: 36px;
  background: rgba(255, 255, 255, 0.8);
  display: inline-block;
  transition: ease-in-out 0.3s;
  color: #ffc451;
}

.team .member .social a:hover {
  color: #fff;
  background: #ffc451;
}

.team .member .social i {
  font-size: 18px;
}

.team .member .member-info {
  padding: 25px 15px;
}

.team .member .member-info h4 {
  font-weight: 700;
  margin-bottom: 5px;
  font-size: 18px;
  color: black;
}

.team .member .member-info span {
  display: block;
  font-size: 13px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.8);
}

.team .member .member-info p {
  font-style: italic;
  font-size: 14px;
  line-height: 26px;
  color: #777777;
}

.team .member:hover .social {
  opacity: 1;
  bottom: 15px;
}

@media (max-width: 768px) {
  .section-title p {
    font-size: 20px;
  }
}

</style>