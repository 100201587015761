<template>
    <div class="home">
        <Navbar />
        <Hero />
        <PromotionalBanner />
        <BestSelling />
        <Category />
        <OurTeam />
        <WhyChooseUs />
        <Footer />
    </div>
</template>

<script>
import Hero from '../sections/Hero'
import PromotionalBanner from '../sections/PromotionBanner'
import BestSelling from '../sections/BestSelling'
import Category from '../sections/Category'
import OurTeam from '../sections/OurTeam'
import WhyChooseUs from '../sections/WhyChooseUs'
import {fb} from '../firebase'

export default {
    name: 'home',
    components: {
        Hero,
        PromotionalBanner,
        BestSelling,
        Category,
        OurTeam,
        WhyChooseUs,
    },
    metaInfo: {
      title: 'My Awesome Webapp',
    },
    data(){
      return{
        user: fb.auth().currentUser,
      }
    },
    methods: {
      checkSignedIn(){
        if(this.user == null && this.$store.state.profile.name != null){
          this.$store.commit('removeUserData')
        }
      },
    },
    created(){
      this.checkSignedIn()
    }
}
</script>

<style scoped>
.home {
  font-family: "Open Sans", sans-serif;
  color: #444444;
}

a {
  color: #ffc451;
}

a:hover {
  color: #ffd584;
  text-decoration: none;
}

h1, h2, h3, h4, h5, h6 {
  font-family: "Raleway", sans-serif;
}
</style>