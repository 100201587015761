<template>
    <div class="login">
        <div class="modal fade" id="login" tabindex="-1" role="dialog" aria-labelledby="loginTitle" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">

                    <div class="modal-body">

                        <ul class="nav nav-fill nav-pills mb-3" id="pills-tab" role="tablist">
                            <li class="nav-item">
                                <a class="nav-link active" id="pills-home-tab" data-toggle="pill" href="#pills-login" role="tab" aria-controls="pills-login" aria-selected="true">Login</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" id="pills-register-tab" data-toggle="pill" href="#pills-register" role="tab" aria-controls="pills-register" aria-selected="false">Signup</a>
                            </li>
                        </ul>

                        <div class="tab-content" id="pills-tabContent">
                            <div class="tab-pane fade show active" id="pills-login" role="tabpanel" aria-labelledby="pills-login-tab">
                                
                                <h5 class="text-center">Login Please</h5>
                                <div class="form-group">
                                    <label for="exampleInputEmail1">Email address</label>
                                    <input type="email" v-model="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter email">
                                    <small class="form-text text-muted">We'll never share your email with anyone else.</small>
                                </div>
                                <div class="form-group">
                                    <label for="exampleInputPassword1">Password</label>
                                    <input type="password" @keyup.enter="login" v-model="password" class="form-control" id="exampleInputPassword1" placeholder="Password">
                                </div>

                                <div class="form-group">
                                    <button class="btn btn-primary" :disabled="disable" @click="login">Login</button>
                                </div>
                                <p @click="resetPassword" class="forgot-password" title="Enter E-mail first">Forgot password?</p>

                            </div>
                            <div class="tab-pane fade" id="pills-register" role="tabpanel" aria-labelledby="pills-register-tab">
                                
                                <h5 class="text-center">Create New Account</h5>
                                
                                <div class="form-group">
                                    <label for="name">Your name</label>
                                    <input type="text" v-model="name" class="form-control" id="name" placeholder="Your nice name">
                                </div>

                                <div class="form-group">
                                    <label for="email">Email address</label>
                                    <input type="email"  v-model="email" class="form-control" id="email" aria-describedby="emailHelp" placeholder="Enter email">
                                </div>
                                <div class="form-group">
                                    <label for="password">Password</label>
                                    <input type="password" @keyup.enter="register" v-model="password" class="form-control" id="password" placeholder="Password">
                                </div>

                                <div class="form-group">
                                    <button class="btn btn-primary" :disabled="disable" @click="register">Signup</button>
                                </div>

                            </div>
                        </div>
                        
                    </div>
            
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {fb,db} from '../firebase'

export default {
    name: "login",
    data(){
        return{
            disable: false,
            name: null,
            email: null,
            password: null,
            profile: {
                name: null,
                email: null,
                address: null,
                phone: null,
                postCode: null,
                picture: '/img/user.png',
                orderCount: '0',
            }
        }
    },
    watch: {
      //eslint-disable-next-line no-unused-vars
      '$route' (to,from) {
        window.$('#login').modal('hide')
        // this.pageId = to
        // this.getData()
      }
    },
    methods: {
        resetPassword(){
            fb.auth().sendPasswordResetEmail(this.email)
                .then(() => {
                    window.Toast.fire({
                        icon: 'success',
                        title: 'Reset link sent to your e-mail'
                    })  
                })
                .catch((error) => {
                    console.log(error);
                })
        },
        login(){
            this.disable = true
            fb.auth().signInWithEmailAndPassword(this.email,this.password)
                .then(() => { //user in brackets
                    window.$('#login').modal('hide')
                    this.$router.push('/profile/overview')
                })
                .catch((error) => {
                    this.disable =false
                    var errorCode = error.code
                    var errorMessage = error.message

                    if(errorCode == 'auth/wrong-password'){
                        window.Toast.fire({
                            icon: 'error',
                            title: 'Wrong Password',
                        })
                    }else {
                        alert(errorMessage)
                    }
                    console.log(error)
                })
        },
        register(){
            this.disable = true
            if(this.name != null){
                if(this.name.trim().length > 3){
                    fb.auth().createUserWithEmailAndPassword(this.email,this.password)
                        .then((user) => {
                            this.profile.name = this.name
                            this.profile.email = this.email
                            db.collection('profiles').doc(user.user.uid).set(this.profile)
                                .then(() => {
                                    window.$('#login').modal('hide')
                                    this.$store.commit('addUser',this.profile)
                                    this.$router.push('/profile/overview')
                                })
                                .catch((error) => {
                                    console.log(error);
                                })
                        })
                        .catch((error) => {
                            this.disable = false
                            var errorCode = error.code;
                            var errorMessage = error.message;
                            if (errorCode == 'auth/weak-password'){
                                window.Toast.fire({
                                    icon: 'error',
                                    title: 'The password is too weak',
                                })
                            }else {
                                alert(errorMessage)
                            }
                            console.log(error)
                        }) 
                }else{
                    this.disable =false
                    window.Toast.fire({
                        icon: 'error',
                        title: 'Name must have atleast 3 characters',
                    })
                }
            }else{
                this.disable =false
                window.Toast.fire({
                    icon: 'error',
                    title: 'Name cannot be blank',
                })
            }
            
        }
    },
}
</script>

<style scoped lang="scss">
.forgot-password{
    color: darkblue;
    cursor: pointer;
    width: fit-content;
}

</style>