<template>
    <section id="admin" class="admin">
        <div class="container mt-5 pt-5">
            <h3 class="d-inline-block mb-3">Products List</h3>
            <button @click="addNew" class="btn btn-primary float-right">Add Product</button>

            <div class="table-responsive">
                <table class="table table-hover">
                    <thead>
                        <tr>
                            <th scope="col" class="border-0 bg-light">
                                <div class="p-2 text-uppercase">Name</div>
                            </th>
                            <th scope="col" class="border-0 bg-light">
                                <div class="py-2 text-uppercase">Price</div>
                            </th>
                            <th scope="col" class="border-0 bg-light">
                                <div class="py-2 text-uppercase">Stock</div>
                            </th>
                            <th scope="col" class="border-0 bg-light">
                                <div class="py-2 px-3 text-uppercase">Modify</div>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr :key="product.index" v-for="product in products">
                            <td class="border-0 align-middle"><strong>{{product.data().name}}</strong></td>
                            <td class="border-0 align-middle"><strong>&#8377;{{product.data().price}}.00</strong></td>
                            <td class="border-0 align-middle"><strong>{{product.data().stock}}</strong></td>
                            <td class="border-0 align-middle">
                                <button @click="editProduct(product)" class="btn btn-primary p-1">Edit</button>
                                <button @click="deleteProduct(product.id)" class="btn btn-danger p-1">Delete</button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div class="modal fade" id="product" tabindex="-1" role="dialog" aria-labelledby="editLabel" aria-hidden="true">
                <div class="modal-dialog modal-lg" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="editLabel" v-if="modal == 'new'">Add Product</h5>
                            <h5 class="modal-title" id="editLabel" v-if="modal == 'edit'">Edit Product</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>

                        <div class="modal-body">
                            <div class="row">
                                <!-- main product -->
                                <div class="col-8">
                                    <div class="form-group">
                                        <input type="text" placeholder="Product Name" v-model="product.name" class="form-control">
                                    </div>

                                    <div class="form-group">
                                        <input type="text" placeholder="Tag Line" v-model="product.tagLine" class="form-control">
                                    </div>

                                    <div class="form-group">
                                        <textarea class="form-control" v-model="product.description" cols="65" rows="10" placeholder="Product Description"></textarea>
                                    </div>
                                    
                                    <div class="form-group">
                                        <input type="text" placeholder="Header Line" v-model="product.headerLine" class="form-control">
                                    </div>

                                    <div class="form-group">
                                        <input @keyup.enter="addPointer" type="text" placeholder="Product Pointers" v-model="pointer" class="form-control">
                                    
                                        <div  class="d-flex">
                                            <p :key="index" v-for="(pointer,index) in product.pointers">
                                                <span class="p-1">{{pointer}}</span>
                                                <span class="delete-tag float-right pr-1" @click="deleteFromArray(product.pointers,index)">X</span>
                                            </p>

                                        </div>
                                    </div>
                                </div>
                                <!-- product sidebar -->
                                <div class="col-4">
                                    <h4 class="display-6">Product Details</h4>
                                    <hr>

                                    <div class="form-group">
                                        <input type="checkbox" v-model="product.bestSeller">
                                        <label for="checkbox">Best Seller</label>
                                    </div>

                                    <div class="form-group">
                                        <input type="text" placeholder="Product MRP" v-model="product.mrp" class="form-control">
                                    </div>

                                    <div class="form-group">
                                        <input type="text" placeholder="Product price" v-model="product.price" class="form-control">
                                    </div>
                                    
                                    <div class="form-group">
                                        <input type="text" placeholder="Stock" v-model="product.stock" class="form-control">
                                    </div>

                                    <div class="form-group">
                                        <input type="text" placeholder="Times Ordered" v-model="product.timesOrdered" class="form-control">
                                    </div>

                                    <div class="form-group">
                                        <input @keyup.enter="addCategory" type="text" placeholder="Product Categories" v-model="category" class="form-control">
                                    
                                        <div  class="d-flex">
                                            <p :key="index" v-for="(category,index) in product.categories">
                                                <span class="p-1">{{category}}</span>
                                                <span class="delete-tag float-right pr-1" @click="deleteFromArray(product.categories,index)">X</span>
                                            </p>

                                        </div>
                                    </div>

                                    <div class="form-group">
                                        <input @keyup.enter="addSize" type="text" placeholder="Product Sizes" v-model="size" class="form-control">
                                    
                                        <div  class="d-flex">
                                            <p :key="index" v-for="(size,index) in product.sizes">
                                                <span class="p-1">{{size}}</span>
                                                <span class="delete-tag float-right pr-1" @click="deleteFromArray(product.sizes,index)">X</span>
                                            </p>

                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <input @keyup.enter="addColor" type="text" placeholder="Product Colors" v-model="color" class="form-control">
                                    
                                        <div  class="d-flex">
                                            <p :key="index" v-for="(color,index) in product.colors">
                                                <span class="p-1">{{color}}</span>
                                                <span class="delete-tag float-right pr-1" @click="deleteFromArray(product.colors,index)">X</span>
                                            </p>

                                        </div>
                                    </div>

                                    <div class="form-group">
                                        <input @keyup.enter="addImage" type="text" placeholder="Product Images" v-model="image" class="form-control">
                                    </div>

                                    <div class="form-group d-flex">
                                        <div class="p-1" :key="index" v-for="(image,index) in product.images">
                                            <div class="img-wrapp">
                                                <img class="p-img" :src="image" alt="" width="80px">
                                                <span class="delete-img float-right" @click="deleteFromArray(product.images,index)">X</span>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div class="col-12">
                                    <div class="form-group">
                                        <textarea class="form-control" v-model="question" cols="65" rows="1" placeholder="FAQ question"></textarea>
                                    </div>

                                    <div class="form-group">
                                        <textarea class="form-control" v-model="answer" cols="65" rows="2" placeholder="FAQ Answer"></textarea>
                                    </div>
                                    <button @click="addFAQ" type="button" class="btn btn-primary">Add FAQ</button>
                                    <br>
                                    <div :key="index" v-for="(qna,index) in product.faqs">
                                        {{qna.question}}
                                        <span class="delete-tag" @click="deleteFaq(index)">X</span>
                                        <br>{{qna.answer}}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                            <button @click="addProduct()" type="button" class="btn btn-primary" v-if="modal == 'new'">Save changes</button>
                            <button @click="updateProduct()" type="button" class="btn btn-primary" v-if="modal == 'edit'">Apply changes</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import {db} from '../../firebase'

export default {
    name: "admin",
    components: {
        
    },
    data(){
        return{
            products: [],
            product: {
                name: null,
                tagLine: null,
                description: null,
                mrp: null,
                price: null,
                stock: null,
                timesOrdered: null,
                bestSeller: false,
                headerLine: null,
                categories: [],
                sizes: [],
                colors: [],
                pointers: [],
                images: [],
                faqs: [],
            },
            activeItem: null,
            modal: null,
            pointer: null,
            question: null,
            answer: null,
            size: null,
            color: null,
            category: null,
            image: null,
        }
    },
    firestore(){
        return{
            products: db.collection('products'),
        }
    },
    methods: {
        addImage(){
            this.product.images.push(this.image)
            this.image = ""
        },
        addColor(){
            this.product.colors.push(this.color)
            this.color = ""
        },
        addSize(){
            this.product.sizes.push(this.size)
            this.size = ""
        },
        addCategory(){
            this.product.categories.push(this.category)
            this.category = ""
        },
        deleteFaq(index){
            this.product.faqs.splice(index,1)
        },
        addFAQ(){
            this.product.faqs.push({
                question: this.question,
                answer: this.answer
            })
            this.question = ""
            this.answer = ""
        },
        deleteFromArray(array,index){
            array.splice(index,1)
        },
        // deleteImage(img,index){
        //     var image = fb.storage().refFromURL(img)

        //     this.products.images.splice(index,1)
        //     image.delete()
        //         .then(() => {
        //             console.log('Image Deleted')
        //         })
        //         .catch((error) => {
        //             console.log(error)
        //         })
        // },
        // uploadImage(e){
        //     if(e.target.files[0]){

        //         var file = e.target.files[0]
        //         console.log(file.name)
                
        //         var storageRef = fb.storage().ref().child(file.name)
        //         const metadata = { contentType: 'image/jpeg' };
    
        //         var uploadTask = storageRef.put(file,metadata)
    
        //         // eslint-disable-next-line no-unused-vars
        //         uploadTask.on('state_changed',(snapshot) => {
        //             // While uploading image this function runs
        //             // eslint-disable-next-line no-unused-vars
        //         },(error) => {
        //             // For error this function runs
        //         } ,() => {
        //             uploadTask.snapshot.ref.getDownloadURL()
        //                 .then((downloadURL) => {
        //                     this.product.images.push(downloadURL)
        //                 })
        //         })
        //     }
        // },
        addPointer(){
            console.log(this.pointer)
            this.product.pointers.push(this.pointer)
            this.pointer = ""
        },
        addNew(){
            this.modal = 'new'
            this.reset()
            window.$("#product").modal("show")
        },
        watcher(){
            db.collection('products').onSnapshot((querySnapshot) => {
                this.products = []
                querySnapshot.forEach((doc) => {
                    this.products.push(doc)
                }) 
            })
        },
        updateProduct(){
            db.collection('products').doc(this.activeItem).update(this.product)
                .then(() => {
                    this.watcher()
                    window.Toast.fire({
                        icon: 'success',
                        title: 'Updated Successfully'
                    })
                    window.$("#product").modal("hide")
                })
                .catch((error) => {
                    console.log('Error updating product ', error)
                })
        },
        editProduct(product){
            this.modal = 'edit'
            window.$('#product').modal("show")
            this.product = product.data()
            this.activeItem = product.id
        },
        deleteProduct(id){
            window.Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
            }).then((result) => {
                if(result.value){
                    db.collection('products').doc(id).delete()
                        .then(() => {
                            this.watcher()
                            window.Toast.fire({
                                icon: 'success',
                                title: 'Deleted Successfully'
                            })
                        })
                        .catch((error) => {
                            console.log('Error deleting product ', error)
                        })
                }
            })
        },
        getProduct(){
            db.collection('products').get()
                .then((querySnapshot) => {
                    this.products = []
                    querySnapshot.forEach((doc) => {
                        this.products.push(doc)
                    })
                })
        },
        addProduct(){
            db.collection('products').add(this.product)
                .then(() => {
                    window.Toast.fire({
                        icon: 'success',
                        title: 'Product Created Successfully'
                    })
                    window.$("#product").modal("hide")
                    this.getProduct()
                })
                .catch((error) => {
                    console.log("Error adding product" , error) 
                })
        },
        reset(){
            this.product = {
                name: null,
                tagLine: null,
                description: null,
                mrp: null,
                price: null,
                stock: null,
                timesOrdered: null,
                bestSeller: false,
                headerLine: null,
                categories: [],
                sizes: [],
                colors: [],
                pointers: [],
                images: [],
                faqs: [],
            }
        }
    },
    created(){
        this.getProduct()
    }
    
}
</script>

<style scoped lang="scss">

#admin {
    background: var(--bg-color);
}

.p-img{
    height: 50px;
    width: 50px;
}

.img-wrapp{
  position: relative;
}

.img-wrapp span.delete-img:hover{
  cursor: pointer;
}

.delete-tag:hover{
    cursor: pointer;
}
</style>