<template>
    <section id="recommended" class="recommended">
      <div class="container" data-aos="fade-up">

        <div class="section-title">
          <p>You might also like ...</p>
        </div>

        <div class="row">
          <div v-for="product in products.splice(0,4)" :key="product.index" class="product-card col-xl-3 col-lg-4 col-md-4 col-6">
            <div class="product">
              <div class="product-img">
                <router-link :to="{name: 'productPage' , params: {id: product.id}}">
                  <img :src="product.data().images[0]" class="img-fluid" alt="">
                  <div class="discount p-2">{{Math.ceil(((product.data().mrp - product.data().price)/product.data().mrp)*100) }}%</div>
                </router-link>
                <!--
                <div class="social">
                  <a href=""><i class="fa fa-whatsapp"></i></a>
                  <a href=""><i class="fa fa-facebook"></i></a>
                  <a href=""><i class="fa fa-instagram"></i></a>
                  <a href=""><i class="fa fa-linkedin-square"></i></a>
                </div>
                -->
              </div>
              <div class="product-info">
                <h4><router-link :to="{name: 'productPage' , params: {id: product.id}}">{{product.data().name}}</router-link></h4>
                <p class="price"><strong>&#8377;{{ product.data().price }}</strong>
                  <span class="mrp">&#8377;{{ product.data().mrp }}</span>
                </p>
              </div>
            </div>
          </div>
        </div>

      </div>
    </section>
</template>

<script>
import {db} from '../firebase'

export default {
  name: "recommended",
  components: {
  },
  props: {
    category: Array,
    pId: String,
  },
  data(){
    return {
        products: [],
        categories: this.category,
        id: this.pId,
    }
  },
  methods:{
    getImage(images){
      return images[0];
    },
    getProducts(){
      db.collection('products').where("categories", "array-contains-any",this.categories).orderBy("name","asc").limit(5).get()
          .then((querySnapshot) => {
              this.products = []
              querySnapshot.forEach((doc) => {
                if (doc.id != this.id){
                  this.products.push(doc)
                }
              })
          })
    }
  },
  created(){
    this.getProducts()
  },
  firestore(){
      return {
        products: db.collection('products'),
      }
  },
};
</script>

<style scoped>
#recommended {
  background: var(--bg-color);
  padding-top: 10px;
}

#recommended .container {
    padding-bottom: 20px;
}

.section-title {
  padding-bottom: 40px;
}

.section-title p {
  margin: 0;
  font-size: 36px;
  font-weight: 700;
  text-transform: uppercase;
  color: #151515;
}

#recommended .product-card {
  padding-left: 5px;
  padding-right: 5px;
  margin-bottom: 15px;
}

#recommended .product {
  overflow: hidden;
  border-radius: 5px;
  background: var(--bg-color);
}

#recommended .product .product-img {
  position: relative;
  overflow: hidden;
}

#recommended .product .social {
  position: absolute;
  left: 0;
  bottom: 30px;
  right: 0;
  opacity: 0;
  transition: ease-in-out 0.3s;
  text-align: center;
}

#recommended .product .social a {
  transition: color 0.3s;
  color: #151515;
  margin: 0 3px;
  padding-top: 7px;
  border-radius: 4px;
  width: 36px;
  height: 36px;
  background: rgba(255, 255, 255, 0.8);
  display: inline-block;
  transition: ease-in-out 0.3s;
  color: #ffc451;
}

#recommended .product .social a:hover {
  color: #fff;
  background: #ffc451;
}

#recommended .product .social i {
  font-size: 18px;
}

#recommended .product .product-info {
  padding: 10px 5px;
}

#recommended .product .product-info h4 {
  margin-bottom: 3px;
  font-size: 16px;
  color: #151515;
  text-align: center;
}

#recommended .product .product-info h4 a {
  color: black;
  text-decoration: none;
}

#recommended .product .product-info .price {
  text-align: center;
  font-size: 15px;
  font-weight: 400;
  color: #e50f0f;
  margin-bottom: 0px;
}

#recommended .product .product-info .mrp {
  margin-left: 5px;
  text-align: center;
  font-style: italic;
  font-size: 14px;
  line-height: 19px;
  color: #575757;
  text-decoration: line-through;
  margin-bottom: 0;
}

#recommended .product:hover .social {
  opacity: 1;
  bottom: 15px;
}

#recommended .product:hover {
  transform: translateY(-10px);
  box-shadow: 0px 0 25px 0 rgb(0, 0, 0);
}

#recommended .product:hover img {
  transform: scale(1.2);
}

.discount {
    position: absolute;
    top: 8px;
    left: 8px;
    background-color: red;
    color: white;
    border-radius: 50%;
}

@media (max-width: 992px) {
    #recommended .section-title p{
        font-size: 20px;
    }
    #recommended .product-card {
      padding-left: 5px;
      padding-right: 5px;
    }
}

</style>