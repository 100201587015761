<template>
    <Navbar />
    <section id="refund-policy" class="refund-policy">
        <div class="container">
            <h1 class="header">Refund Policy</h1>
            <h3>CANCELLATION BEFORE SHIPMENT:</h3>
            <p>Order once placed can only be cancelled within 4 hours from the time of placing the order, you can write to our customer support team on <a>support@trinique.in</a> or call us on +91 9717129243 In such cases, the order will be cancelled and the money will be refunded to you within 48-72 business hours after the cancellation request.</p>
            <h3>EXCHANGE POLICY</h3>
            <p>We only exchange goods if they are defective, damaged or lost in transit. In circumstances where you consider that a product is defective, you should promptly contact us on support@trinique.in or Whatsapp us on +91 9717129243 with details of the product and the defect. Upon receipt of the returned product, we will fully examine it and notify you via e-mail, within a reasonable period of time, whether you are entitled to a replacement as a result of the defect. If you are eligible, we will send you a replacement product.</p>
            <p>We do not accept the replacement after the 7 days of delivery of product.</p>
            <p>To be eligible for a replacement, your item must be in the same condition that you received it, unworn or unused, with tags, and in its original packaging. You’ll also need the receipt or proof of purchase. </p>
            <p>To start a replacement, you can contact us at support@trinique.in. If your return is accepted, we’ll send you a return shipping label, as well as for instructions on how and where to send your package. Items sent back to us without first requesting a return will not be accepted. </p>
            <p>You can always contact us for any return question at support@trinique.in.</p>
            <h4>Damages and issues </h4>
            <p>Please inspect your order upon reception and contact us immediately if the item is defective, damaged or if you receive the wrong item so that we can evaluate the issue and make it right.</p>
            <h4>Exceptions / non-returnable items </h4>
            <p>Certain types of items cannot be returned, like perishable goods (such as food, flowers, or plants), custom products (such as special orders or personalized items), and personal care goods (such as beauty products). We also do not accept returns for hazardous materials, flammable liquids, or gases. Please get in touch if you have questions or concerns about your specific item. </p>
            <p>Unfortunately, we cannot accept returns on sale items or gift cards.</p>
            <h4>Exchanges </h4>
            <p>The fastest way to ensure you get what you want is to return the item you have, and once the return is accepted, make a separate purchase for the new item.</p>
            <h3>RETURN & REFUND POLICY</h3>
            <p>At Trinique, we do our best to ensure that you are completely satisfied with our products. And we are happy to issue a full refund based on the conditions listed below:</p>
            <h4>Full Refunds Possible If:</h4>
            <p>you received the ordered item(s) is past its expiry date.Please note: Mode of refund may vary depending on circumstances. If the mode of refund is by Credit/Debit Card or Net Banking, please allow 10 to 14 working days for the credit to appear in your account. While we regret any inconvenience caused by this time frame, it is the bank’s policy that delays the refund timing and we have no control over that. If the mode of refund is by e-wallet, credit should be available within 24 hours.</p>
            <p>During peak seasons please allow up to 15-18 business days for your return to process.</p>
            <h4>How to Request a Refund:</h4>
            <p>To request a refund, simply email us your order details, including the reason why you’re requesting a refund. We take customer feedback very seriously and use it to constantly improve our quality of service.</p>
            <p>If you have any queries, contact us on support@trinique.in or Whatsapp us on +91 9717129243</p>
        </div>
    </section>
    <Footer />
</template>

<script>
export default {
    name: 'refundPolicy',
    
}
</script>

<style scoped>
#refund-policy {
    background: var(--bg-color);
}

#refund-policy .container {
    position: relative;
    padding: 74px 13px;
}

#refund-policy .header {
    text-align: center;
}
</style>