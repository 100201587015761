import firebase from 'firebase'
//require("firebase/firestore")
import 'firebase/firestore'

var firebaseConfig = {
    apiKey: "AIzaSyC5t1XN3xc5mVMBAW8IgCqVTXp2j4PLSwo",
    authDomain: "e-commerece-2cc42.firebaseapp.com",
    databaseURL: "https://e-commerece-2cc42-default-rtdb.firebaseio.com",
    projectId: "e-commerece-2cc42",
    storageBucket: "e-commerece-2cc42.appspot.com",
    messagingSenderId: "640787489761",
    appId: "1:640787489761:web:cde20b015421877bfde8a0",
    measurementId: "G-FPVKF4ELDV"
};
// Initialize Firebase
const fb = firebase.initializeApp(firebaseConfig);

const db = firebase.firestore();

export {fb,db}