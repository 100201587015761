<template>
    <section id="admin" class="admin">
        <div class="container mt-5 pt-5">
            <h3 class="d-inline-block mb-3">Phones List</h3>
            <button @click="addNew" class="btn btn-primary float-right">Add Phones</button>

            <div class="table-responsive">
                <table class="table table-hover">
                    <thead>
                        <tr>
                            <th scope="col" class="border-0 bg-light">
                                <div class="p-2 text-uppercase">Name</div>
                            </th>
                            <th scope="col" class="border-0 bg-light">
                                <div class="py-2 text-uppercase">Link</div>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr :key="product.index" v-for="product in products">
                            <td class="border-0 align-middle"><strong>{{product.data().name}}</strong></td>
                            <td class="border-0 align-middle"><strong>{{product.data().link}}</strong></td>
                            <td class="border-0 align-middle">
                                <button @click="editProduct(product)" class="btn btn-primary p-1">Edit</button>
                                <button @click="deleteProduct(product.id)" class="btn btn-danger p-1">Delete</button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div class="modal fade" id="product" tabindex="-1" role="dialog" aria-labelledby="editLabel" aria-hidden="true">
                <div class="modal-dialog modal-lg" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="editLabel" v-if="modal == 'new'">Add Phone</h5>
                            <h5 class="modal-title" id="editLabel" v-if="modal == 'edit'">Edit Phone</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>

                        <div class="modal-body">
                            <div class="row">
                                <!-- main product -->
                                <div class="col-8">
                                    <div class="form-group">
                                        <input type="text" placeholder="Phone Name" v-model="product.name" class="form-control">
                                    </div>

                                    <div class="form-group">
                                        <input type="text" placeholder="Link" v-model="product.link" class="form-control">
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                            <button @click="addProduct()" type="button" class="btn btn-primary" v-if="modal == 'new'">Save changes</button>
                            <button @click="updateProduct()" type="button" class="btn btn-primary" v-if="modal == 'edit'">Apply changes</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import {db} from '../../firebase'

export default {
    name: "admin",
    components: {
        
    },
    data(){
        return{
            products: [],
            product: {
                name: null,
                link: null
            },
            activeItem: null,
            modal: null,
            pointer: null,
            question: null,
            answer: null,
            size: null,
            color: null,
            category: null,
            image: null,
        }
    },
    firestore(){
        return{
            products: db.collection('phones'),
        }
    },
    methods: {
        addNew(){
            this.modal = 'new'
            this.reset()
            window.$("#product").modal("show")
        },
        watcher(){
            db.collection('phones').onSnapshot((querySnapshot) => {
                this.products = []
                querySnapshot.forEach((doc) => {
                    this.products.push(doc)
                }) 
            })
        },
        updateProduct(){
            db.collection('phones').doc(this.activeItem).update(this.product)
                .then(() => {
                    this.watcher()
                    window.Toast.fire({
                        icon: 'success',
                        title: 'Updated Successfully'
                    })
                    window.$("#product").modal("hide")
                })
                .catch((error) => {
                    console.log('Error updating product ', error)
                })
        },
        editProduct(product){
            this.modal = 'edit'
            window.$('#product').modal("show")
            this.product = product.data()
            this.activeItem = product.id
        },
        deleteProduct(id){
            window.Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
            }).then((result) => {
                if(result.value){
                    db.collection('phones').doc(id).delete()
                        .then(() => {
                            this.watcher()
                            window.Toast.fire({
                                icon: 'success',
                                title: 'Deleted Successfully'
                            })
                        })
                        .catch((error) => {
                            console.log('Error deleting product ', error)
                        })
                }
            })
        },
        getProduct(){
            db.collection('phones').get()
                .then((querySnapshot) => {
                    this.products = []
                    querySnapshot.forEach((doc) => {
                        this.products.push(doc)
                    })
                })
        },
        addProduct(){
            db.collection('phones').add(this.product)
                .then(() => {
                    window.Toast.fire({
                        icon: 'success',
                        title: 'Product Created Successfully'
                    })
                    window.$("#product").modal("hide")
                    this.getProduct()
                })
                .catch((error) => {
                    console.log("Error adding product" , error) 
                })
        },
        reset(){
            this.product = {
                name: null,
                link: null
            }
        }
    },
    created(){
        this.getProduct()
    }
    
}
</script>

<style scoped lang="scss">

#admin {
    background: var(--bg-color);
}

.p-img{
    height: 50px;
    width: 50px;
}

.img-wrapp{
  position: relative;
}

.img-wrapp span.delete-img:hover{
  cursor: pointer;
}

.delete-tag:hover{
    cursor: pointer;
}
</style>