import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import jQuery from 'jquery'
import 'popper.js'
import 'bootstrap'
import './assets/app.scss'
import Navbar from './components/Navbar'
import Login from './components/Login'
import AddToCart from './components/AddToCart'
import MiniCart from './components/MiniCart'
import Footer from './components/Footer'
import { fb } from './firebase'
import Swal from 'sweetalert2'
import store from './store'
//import {Carousel,Slide,Navigation,Pagination} from 'vue3-carousel'
//import 'vue3-carousel/dist/carousel.css';

window.$ = window.jQuery = jQuery
window.Swal = Swal

const Toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 3000
})

window.Toast = Toast

const app = createApp(App)

app.component('Navbar',Navbar)
app.component('Login',Login)
app.component('AddToCart',AddToCart)
app.component('MiniCart',MiniCart)
app.component('Footer',Footer)
//app.component('Carousel',Carousel)
// app.component('Slide',Slide)
// app.component('Navigation',Navigation)
// app.component('Pagination',Pagination)

app.directive('scroll', {
  mounted (el, binding) {
    let f = function (evt) {
      if (binding.value(evt, el)) {
        window.removeEventListener('scroll', f)
      }
    }
    window.addEventListener('scroll', f)
  }
})

function checkAuthStatus() {
    return new Promise( (resolve, reject) => {
      try {
        fb.auth().onAuthStateChanged(user => resolve(user))
      } catch(err) {
        reject(err)
      }
    })
  }

  checkAuthStatus().then(() => {
    app.use(router)
    app.use(store) 
    app.mount('#app')
  })

// console.log("Auth changed starting")
// fb.auth().onAuthStateChanged((user) => {
//     console.log("in Auth changed ")
    

    
    
// })
// console.log("Auth changed ended")
// createApp(App).use(router).mount('#app')