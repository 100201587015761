<template>
    <section id="review" class="review">
        <button @click="addNew" class="btn btn-primary mb-3">Write a Review</button>
        <div class="icon-box" :key="index" v-for="(review,index) in reviews" :class="index == reviews.length-1 ? 'last-item' : ''">
            <img :src="review.data().image" class="img-fluid col-2 col-lg-1" alt="">
            <h3 class="col-9 col-lg-11">
                {{review.data().name}}
                    <ul class='rating-stars'>
                        <li :key="index" v-for="(star,index) in 5">
                            <i class="fa" :class="review.data().stars - 1 >= index ? 'fa-star' : 'fa-star-o'"></i>
                        </li>
                        <li>
                            <span> - {{review.data().date}}</span>
                        </li>
                    </ul>
                <p class="u-review">{{review.data().description}}</p>
                <p v-if="review.id == user.uid" class="u-actions">
                    <span @click="editReview(review)" class="edit">Edit</span>
                    <span @click="deleteReview" class="delete">Delete</span>
                </p>
            </h3>
        </div>

        <!-- Review form modal -->
        <div class="modal fade" id="review-form" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">Write a Review</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <ul class='rating-stars'>
                            <li :key="index" v-for="index in 5">
                                <i @click="active = index" class="fa" :class="active >= index ? 'fa-star' : 'fa-star-o'"></i>
                            </li>
                        </ul>
                        <div class="form-group">
                            <textarea class="form-control" cols="35" rows="2" v-model="review.description" placeholder="Your Review"></textarea>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button v-if="modal == 'new'" @click="addReview" type="button" class="btn btn-primary">Submit</button>
                        <button v-if="modal == 'edit'" @click="updateReview" type="button" class="btn btn-primary">Apply Changes</button>
                    </div>
                </div>
            </div>
        </div>
        <!-- Review form modal end -->
    </section>
</template>

<script>
import {fb,db} from '../firebase'

export default {
    name: 'review',
    props: {
        pId: String,
    },
    emits: ['total-stars'],
    data(){
        return{
            id : this.pId,
            active: 5,
            user: fb.auth().currentUser,
            review: {
                image: '/img/user.png',
                name: null,
                description: null,
                stars: null,
                date: null,
            },
            reviews: [],
            totalStars: null,
            modal: null,
        }
    },
    watch: {
      //eslint-disable-next-line no-unused-vars
      '$route' (to,from) {
        window.$('#review-form').modal('hide')
        // this.pageId = to
        // this.getData()
      }
    },
    methods: {
        editReview(review){
            this.modal = "edit"
            window.$('#review-form').modal("show")
            this.review = review.data()
        },
        updateReview(){
            const d = new Date()
            const reviewDate = ("0" + d.getDate()).slice(-2) + "/" + ("0" + (d.getMonth() + 1)).slice(-2) + "/" + d.getFullYear()
            this.review.date = reviewDate
            this.review.name = this.$store.state.profile.name
            this.review.stars = this.active
            db.collection("products").doc(this.id).collection("reviews").doc(this.user.uid).update(this.review)
                .then(() => {
                    this.getReview()
                    window.Toast.fire({
                        icon: 'success',
                        title: 'Review Updated Successfully'
                    })
                    window.$("#review-form").modal("hide")
                })
                .catch((error) => {
                    console.log('Error updating review ', error)
                })
        },
        addNew(){
            if(this.user != null){
                if(this.user.emailVerified){
                    if(this.reviews.findIndex(i => i.id == this.user.uid) == -1){
                        this.modal = "new"
                        window.$('#review-form').modal("show")
                    }else{
                        window.Toast.fire({
                            icon: 'error',
                            title: 'Review already exists'
                        })
                    }
                }else{
                    this.$router.push('/profile/overview')
                }
            }else{
                window.$('#login').modal('show')
            }
        },
        addReview(){
            const d = new Date()
            const reviewDate = ("0" + d.getDate()).slice(-2) + "/" + ("0" + (d.getMonth() + 1)).slice(-2) + "/" + d.getFullYear()
            this.review.date = reviewDate
            this.review.name = this.$store.state.profile.name
            this.review.stars = this.active
            db.collection("products").doc(this.id).collection("reviews").doc(this.user.uid).set(this.review)
                .then(() => {
                    this.getReview()
                    window.Toast.fire({
                        icon: 'success',
                        title: 'Review Added Successfully'
                    })
                    window.$('#review-form').modal("hide")
                }).catch((error) => {
                    console.log(error)
                })
        },
        deleteReview(){
            window.Swal.fire({
                title: 'Are you sure?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
            }).then((result) => {
                if(result.value){
                    db.collection('products').doc(this.id).collection("reviews").doc(this.user.uid).delete()
                        .then(() => {
                            this.getReview()
                            window.Toast.fire({
                                icon: 'success',
                                title: 'Deleted Successfully'
                            })
                        })
                        .catch((error) => {
                            console.log('Error deleting review ', error)
                        })
                }
            })
        },
        getReview(){
            db.collection("products").doc(this.id).collection("reviews").orderBy("date","asc").limit(5).get()
                .then((querySnapshot) => {
                    this.reviews = []
                    this.totalStars = 0
                    querySnapshot.forEach((doc) => {
                        this.reviews.push(doc)
                        this.totalStars += doc.data().stars
                    })
                    this.$emit('total-stars', this.totalStars,this.reviews.length)
                    this.orderArray()
                }).catch((error) => {
                    console.log(error)
                })
        },
        orderArray(){
            this.reviews.sort((a, b) => {
                a = a.data().date.split("/")
                b = b.data().date.split("/")
                var c = new Date(a[2],a[1],a[0]);
                var d = new Date(b[2],b[1],b[0]);
                return d-c;
            });
        },
    },
    created(){
        this.getReview()
    }
}
</script>

<style scoped>
#review .icon-box {
  display: flex;
  padding: 2px;
  padding-top: 10px;
  transition: ease-in-out 0.3s;
  height: 100%;
  text-align: left;
  background: var(--bg-color);
  border-bottom: 1px solid #dee2e6;
}

#review .icon-box img{
    max-height: 3.5rem;
    max-width: 6%;
    padding: 0 1px;
}

#review .icon-box h3 {
  font-weight: 700;
  margin: 0;
  padding: 0 0 0 15px;
  font-size: 15px;
  line-height: 24px;
  color: black;
}

#review .icon-box ul {
    margin: 0;
}

#review .icon-box li {
    margin: 0 2px;
    color: #f3d23e !important;
}

#review .icon-box li span {
    color: rgba(0, 0, 0, 0.6);
    font-weight: normal;
}

#review .last-item {
    border: none;
}

#review .icon-box .u-review{
    font-weight: normal;
    line-height: 18px;
    font-size: 13px;
    margin-bottom: 7px;
}

#review .icon-box .u-actions {
    margin-bottom: 5px;
    font-weight: normal;
    font-size: 13px;
    color: blue;
}

#review .icon-box .edit:hover,.delete:hover {
    cursor: pointer;
    text-decoration: underline;
}

#review .icon-box .delete {
    color: red;
    margin-left: 15px;
}

#review .rating-stars{
    display: flex;
    list-style: none;
    padding: 0;
}

#review-form .rating-stars li{
    margin: 0 2px;
    color: #f3d23e !important;
}

#review-form .rating-stars li i:hover{
    color: #f3d23e;
    cursor: pointer;
}

@media (max-width: 992px) {
    #review .icon-box img{
        max-height: 3rem;
        max-width: 12%;
    }
    #review .icon-box .u-actions {
        font-size: 12px;
    }
}
</style>