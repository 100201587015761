<template>
    <Navbar />
    <section id="checkout" class="d-flex align-items-center justify-content-center">
        <div class="container" data-aos="fade-up">

            <div class="row justify-content-center">
                <div class="col-xl-6 col-lg-8 mb-3">
                    <h1>Checkout</h1>
                </div>
            </div>
            <div class="empty-cart" v-if="$store.getters.totalQuantity == 0">
                <h1>Your Cart is Empty.</h1>
                <h1>Please add item(s) in your cart.</h1>
                <router-link to="/products" class="btn btn-success mt-4">Continue Shopping</router-link>
            </div>

            <h3 v-if="$store.getters.totalQuantity != 0">Your Cart ({{$store.getters.totalQuantity}} items)</h3>

            <div class="row mt-5 justify-content-center" v-if="$store.getters.totalQuantity != 0">
                <div class="col-lg-7">
                    <!-- Cart Details--> 
                    <div class="icon-box" :key="index" v-for="(item,index) in $store.state.cart">
                        <div class="inner-row row">
                            <div class="image col-4 col-lg-3">
                                <span class="side-bar"></span>
                                <router-link :to="{name: 'productPage' , params: {id: item.productId}}"><img :src="item.productImage" alt=""></router-link>
                            </div>
                            <div class="content col-6 col-lg-8">
                                <router-link :to="{name: 'productPage' , params: {id: item.productId}}">{{item.productName}}</router-link>
                                <p class="price">&#8377;{{item.productPrice}}.00</p>
                                <p class="details" v-if="item.productColor || item.productSize">
                                    <span class="color" v-if="item.productColor">Color: {{item.productColor}} </span>
                                    <span class="size" v-if="item.productSize">Size: {{item.productSize}} </span>
                                </p>
                                <p class="quantity">Quantity: 
                                    <i @click="$store.commit('decreaseItemQuantity',item.productId)" class="fa fa-minus btn" aria-hidden="true"></i>
                                    {{item.productQuantity}}
                                    <i @click="$store.commit('increaseItemQuantity',item.productId)" class="fa fa-plus btn" aria-hidden="true"></i>
                                </p>
                            </div>
                            <div class="delete col-1">
                                <a @click="$store.commit('removeFromCart',item)">
                                    <i class="delete-btn fa fa-trash"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                    <!-- Address Details-->
                    <div class="address-details">
                        <div class="header">
                            Address Details
                            <span><a @click="detailsEdit" class="edit-btn">Edit</a></span>
                        </div>
                        <div class="p-4">
                            <p class="font-italic mb-2">Items will be delivered in 3-5 Business Days.</p>
                            <ul class="list-unstyled mb-2">
                                <li><strong class="text-muted">Name </strong><strong>{{ $store.state.profile.name }}</strong></li>
                                <li><strong class="text-muted">Phone Number (+91)</strong><strong> {{$store.state.profile.phone}}</strong></li>
                                <li><strong class="text-muted">Address</strong><strong>{{$store.state.profile.address}}</strong></li>
                                <li><strong class="text-muted">Postal Code</strong><strong>{{$store.state.profile.postCode}}</strong></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <!-- Summary -->
                <div class="col-lg-5">
                    <div class="summary">
                        <div class="header">Order summary </div>
                        <div class="p-4">
                            <p class="font-italic mb-4">Shipping and additional costs are calculated based on values you have entered.</p>
                            <ul class="list-unstyled mb-4">
                                <li><strong class="text-muted">Order Subtotal </strong><strong>&#8377;{{ $store.getters.totalPrice }}.00</strong></li>
                                <li><strong class="text-muted">Shipping and handling</strong><strong>&#8377;0.00</strong></li>
                                <li><strong class="text-muted">Tax</strong><strong>&#8377;0.00</strong></li>
                                <li><strong class="text-muted">Total</strong>
                                    <h5 class="font-weight-bold">&#8377;{{ $store.getters.totalPrice }}.00</h5>
                                </li>
                            </ul>
                            <button @click="checkout" :disabled="disable" class="order-btn">Place Order</button>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </section>
    <Footer />
</template>

<script>
import {fb,db} from '../firebase'

export default {
    name : 'checkout',
    components : {
        
    },
    data(){
        return{
            disable: false,
            deliveryDate1: null,
            deliveryDate2: null,
            user: fb.auth().currentUser,
            order: {
                date: null,
                deliveryDate: null,
                orderId: null,
                items: [],
                totalPrice: null,
                status: 'Ordered',
            },
            profile: {
                name:null,
                email:null,
                phone:null,
                address:null,
                postCode:null,
                picture: null,
                orderCount: null,
            },
            finalOrder: {
                name:null,
                phone:null,
                address:null,
                postCode:null,
                orderCount: null,
                orders: [] 
            }
        }
    },
    methods: {
        detailsEdit(){
            if(this.$store.state.profile.name && this.user != null){
                this.$router.push('/profile/profile-setting')
            }else{
                window.$('#login').modal('show')
            }
        },
        checkout(){
            if(this.$store.state.profile.name && this.user != null){
                if(this.user.emailVerified){
                    if(this.$store.state.profile.phone && this.$store.state.profile.address && this.$store.state.profile.postCode){
                        this.disable = true
                        this.loadingModal()

                        this.order.items = []
                        this.$store.state.cart.forEach(item => {
                            this.order.items.push(item)
                        });

                        this.order.deliveryDate = this.deliveryDate2
                        this.order.totalPrice = this.$store.getters.totalPrice

                        this.incrementOrderCount()

                    }else {
                        this.$router.push('/profile/profile-setting')
                    }
                }else{
                    this.$router.push('/profile/overview')
                }
            }else{
                window.$('#login').modal('show')
            }
        },
        incrementOrderCount(){
            db.collection("profiles").doc(this.user.uid).get()
                .then((querySnapshot) => {
                    this.profile = querySnapshot.data()
                    this.profile.orderCount = String(Number(this.profile.orderCount) + 1)
                    
                    db.collection("profiles").doc(this.user.uid).update(this.profile)
                        .then(() => {
                            this.$store.commit('addUser',this.profile)
                            this.addOrderToProfile()
                        }).catch((error) => {
                            console.log(error)
                            this.ErrorModal()
                        })
                }).catch((error) => {
                    console.log(error)
                    this.ErrorModal()
                })
        },
        addOrderToProfile(){
            db.collection("profiles").doc(this.user.uid).collection("orders").doc(this.order.orderId).set(this.order)
                .then(() => {
                    this.addOrderToOrders()
                }).catch((error) => {
                    console.log(error)
                    this.ErrorModal()
                })
        },
        addOrderToOrders(){
            db.collection("orders").doc(this.user.uid).get()
                .then((querySnapshot) => {
                    console.log(querySnapshot.exists)
                    if(querySnapshot.exists){
                        this.finalOrder = querySnapshot.data()
                        this.finalOrder.orders.push(this.order)
                        this.finalOrder.orderCount = String(Number(this.finalOrder.orderCount) + 1)

                        db.collection("orders").doc(this.user.uid).update(this.finalOrder)
                            .then(() => {
                                this.$store.commit('emptyCart')
                                this.successModal()
                            }).catch((error) => {
                                console.log(error)
                                this.ErrorModal()
                            })
                    }else{
                        this.setFinalOrderData()

                        db.collection("orders").doc(this.user.uid).set(this.finalOrder)
                            .then(() => {
                                this.$store.commit('emptyCart')
                                this.successModal()
                            }).catch((error) => {
                                console.log(error)
                                this.ErrorModal()
                            })
                    }
                }).catch((error) => {
                    console.log(error)
                    this.ErrorModal()
                })
        },
        setFinalOrderData(){
            this.finalOrder.name = this.profile.name
            this.finalOrder.phone = this.profile.phone
            this.finalOrder.address = this.profile.address
            this.finalOrder.postCode = this.profile.postCode
            this.finalOrder.orderCount = this.profile.orderCount

            this.finalOrder.orders.push(this.order)
        },
        successModal(){
            window.Swal.close()
            window.Swal.fire({
                icon: 'success',
                title: 'Order Successfully Placed',
                text: 'Expected Delivery between ' + this.deliveryDate1 + ' - ' + this.deliveryDate2,
                showConfirmButton: true,
                showCancelButton: true,
                confirmButtonText: 'My Orders',
                cancelButtonText: 'Products'
            }).then((result) => {
                if(result.isConfirmed){
                    this.$router.push('/profile/orders')
                }else{
                    this.$router.push('/products')
                }
            })
        },
        ErrorModal(){
            window.Swal.close()
            window.Swal.fire({
                icon: 'error',
                title: 'Something Went Wrong !!',
                text: 'Please try again after some time.',
                showConfirmButton: true,
                showCancelButton: true,
                confirmButtonText: 'My Orders',
                cancelButtonText: 'Products'
            }).then((result) => {
                if(result.isConfirmed){
                    this.$router.push('/profile/orders')
                }else{
                    this.$router.push('/products')
                }
            })
        },
        loadingModal(){
            window.Swal.fire({
                title: 'Please Wait !',
                html: 'Placing Order',// add html attribute if you want or remove
                allowOutsideClick: false,
                allowEscapeKey: false,
                showConfirmButton: false,
                onBeforeOpen: () => {
                    window.Swal.showLoading()
                },
            });
        },
        SetDates(){
            const d = new Date()
            var d1 = new Date()
            var d2 = new Date()

            this.order.date = ("0" + d.getDate()).slice(-2) + '/' + ("0" + (d.getMonth() + 1)).slice(-2) + "/" + d.getFullYear()
            this.order.orderId = "C" + ("0" + d.getDate()).slice(-2) + ("0" + (d.getMonth() + 1)).slice(-2) + ("0" + d.getFullYear()).slice(-2) + ("0" + d.getHours()).slice(-2) + ("0" + d.getMinutes()).slice(-2) + ("0" + d.getSeconds()).slice(-2) + ("00" + d.getMilliseconds()).slice(-3)
            
            d1.setDate(d1.getDate() + 3)
            d2.setDate(d2.getDate() + 5)
            this.deliveryDate1 = ("0" + d1.getDate()).slice(-2) + '/' + ("0" + (d1.getMonth() + 1)).slice(-2) + "/" + d1.getFullYear()
            this.deliveryDate2 = ("0" + d2.getDate()).slice(-2) + '/' + ("0" + (d2.getMonth() + 1)).slice(-2) + "/" + d2.getFullYear()
        }, 
    },
    created() {
        this.SetDates()
    }
}
</script>

<style scoped lang='scss'>
#checkout {
  width: 100%;
  padding-bottom: 30px;
  height: auto;
  background-size: cover;
  position: relative;
  background: var(--bg-color);
}

#checkout:before {
  content: "";
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
}

#checkout .container {
  position: relative;
  padding-top: 74px;
  text-align: center;
}

#checkout h1 {
  margin: 0;
  font-size: 56px;
  font-weight: 700;
  line-height: 64px;
  color: black;
  font-family: "Poppins", sans-serif;
  font-family: var(--header-font);
}

#checkout h3 {
  color: rgba(0, 0, 0, 0.9);
  margin: 10px 0 0 0;
  font-size: 24px;
  text-align: left;
}

#checkout .empty-cart{
    height: auto;
    padding: 90px 0;
}

#checkout .icon-box {
    padding: 10px;
    background: white;
    margin-bottom: 20px;
    box-shadow: 1px 6px 5px #aaaaaa;
}

#checkout .icon-box .side-bar {
    position: absolute;
    top: 0;
    left: 0;
    height: calc(100% + 20px);
    width: 4px;
    background: #ffc451;
    margin-left: -10px;
    margin-top: -10px;
}

#checkout .icon-box .inner-row {
    margin: 0;
}

#checkout .icon-box .image {
    padding: 0;
}

#checkout .icon-box .image img {
    width: 100%;
}

#checkout .icon-box .content {
    text-align: left;
    font-weight: bold;
}

#checkout .icon-box .content a {
    color: black;
    text-decoration: none;
    font-size: 20px;
}

#checkout .icon-box .content .price {
    font-size: 18px;
}

#checkout .icon-box .content .details {
    margin-bottom: 0;
}

#checkout .icon-box .content .color {
    margin-right: 15px;
    text-transform: capitalize;
}

#checkout .icon-box a .delete-btn:hover {
  color: red;
  cursor: pointer;
}

#checkout .address-details {
    background: white;
    box-shadow: 1px 6px 5px #aaaaaa;
    border-radius: 2%;
    margin: 20px 0;
}

#checkout .address-details .header {
    padding: 1rem 1.5rem;
    font-weight: 700;
    text-transform: uppercase;
    border-radius: 0.5rem;
    background-color: #eaeaea
}

#checkout .address-details .header span {
    float: right;
}

#checkout .address-details .edit-btn {
    padding: 0.5rem;
    background: #343a40;
    border-radius: 50rem;
    text-decoration: none;
    color: white;
    font-weight: 400;
}

#checkout .address-details .edit-btn:hover {
    cursor: pointer;
}

#checkout .address-details li {
    display: flex;
    justify-content: space-between;
    padding: 1rem 0;
    border-bottom: 1px solid #dee2e6;
}

#checkout .summary {
    background: white;
    box-shadow: 1px 6px 5px #aaaaaa;
    border-radius: 2%;
}

#checkout .summary .header {
    padding: 1rem 1.5rem;
    font-weight: 700;
    text-transform: uppercase;
    border-radius: 0.5rem;
    background-color: #eaeaea
}

#checkout .summary li {
    display: flex;
    justify-content: space-between;
    padding: 1rem 0;
    border-bottom: 1px solid #dee2e6;
}

#checkout .summary .order-btn {
    padding: 0.5rem 0;
    border-radius: 50rem;
    display: block;
    width: 100%;
    color: #fff;
    background-color: #343a40;
    border-color: #343a40;
    text-align: center;
    font-size: 1rem;
}

@media (min-width: 1024px) {
  #checkout {
    background-attachment: fixed;
  }
}

@media (max-width: 768px) {
  #checkout {
    height: auto;
  }
  #checkout h1 {
    font-size: 28px;
    line-height: 36px;
  }
  #checkout h3 {
    font-size: 20px;
    line-height: 24px;
  }
  #checkout .icon-box {
      padding: 5px;
  }
  #checkout .icon-box .side-bar{
    height: calc(100% + 10px);
    margin-left: -5px;
    margin-top: -5px;
  }
  #checkout .icon-box .content{
      padding-right: 5px;
      line-height: 17px;
  }
  #checkout .icon-box .content a {
      font-size: 14px;
  }
  #checkout .icon-box .content .price{
      font-size: 13px;
  }
  #checkout .icon-box .content .details {
      font-size: 12px;
      margin-bottom: 0;
  }
  #checkout .icon-box .content .quantity {
      font-size: 12px;
      margin-bottom: 0;
  }
  #checkout .icon-box .content .quantity i {
      font-size: 10px;
  }
  #checkout .icon-box a .delete-btn {
      color: red;
  }
}
</style>