<template>
  <div id="app">
    <PreLoader />
    
  </div>
  <router-view/>
</template>

<script>
import PreLoader from './components/PreLoader'
export default {
  name: 'App',
  components: {
    PreLoader,
  }
}
</script>


<style lang="scss">
:root{
  --bg-color: #fafafa;
  --header-font: serif, sans-serif;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  //text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
