<template>
    <footer id="footer">
        <div class="footer-top">
          <div class="container">
              <div class="row">

                <div class="col-lg-5 col-md-6">
                    <div class="footer-info">
                      <h3><img src="../../public/img/logo.jpeg" alt="" class="img-fluid">TRINIQUE</h3>
                      <p>
                          We, at <strong>Trinique</strong>, try each and every day to serve our customers with the highest quality products at the best possible price.
                          <br><br>
                          <a href="tel: 9717129243"><i class="fa fa-phone mr-3"></i>: +91 9717129243</a>
                          <br>
                          <a href="mailto: support@trinique.in"><i class="fa fa-envelope mr-3"></i>: support@trinique.in</a>
                      </p>
                      <div class="social-links mt-3">
                          <a href="https://www.facebook.com/Trinique-109569441350791/" target="_blank" class="facebook" title="Our Facebook Page"><i class="fa fa-facebook"></i></a>
                          <a href="https://instagram.com/trinique.in" target="_blank" class="instagram" title="Our Instagram Page"><i class="fa fa-instagram"></i></a>
                      </div>
                    </div>
                </div>

                <div class="col-lg-3 col-md-6 footer-links">
                    <h4>Useful Links</h4>
                    <ul>
                      <li><i class="fa fa-chevron-right"></i> <router-link to="/">Home</router-link></li>
                      <li><i class="fa fa-chevron-right"></i> <router-link to="/products">Products</router-link></li>
                      <li><i class="fa fa-chevron-right"></i> <router-link to="/faq">FAQ</router-link></li>
                      <li><i class="fa fa-chevron-right"></i> <router-link to="/refund-policy">Refund Policy</router-link></li>
                      <li><i class="fa fa-chevron-right"></i> <router-link to="/terms-and-conditions">Terms and Conditions</router-link></li>
                      <li><i class="fa fa-chevron-right"></i> <router-link to="/privacy-policy">Privacy policy</router-link></li>
                    </ul>
                </div>

                <div class="col-lg-4 col-md-6 footer-links">
                    <h4>Categories</h4>
                    <ul>
                      <li :key="index" v-for="(cat,index) in categories">
                        <i class="fa fa-chevron-right"></i> <router-link :to="{name: 'categoryProduct' , params: {q:encodeURI(cat.name)}}">{{cat.name}}</router-link>
                      </li>
                    </ul>
                </div>

              </div>
          </div>
        </div>

        <div class="container">
          <div class="copyright">
              &copy; Copyright <strong><span>Trinique</span></strong>. All Rights Reserved
          </div>
        </div>
    <div class="fixed-bottom mb-2 mr-3">
        <button @click="contactUs" class="btn btn-success float-right"><i class="fa fa-whatsapp" aria-hidden="true"></i> Contact us</button>
    </div>
    </footer>
</template>

<script>
export default {
    name: 'footer',
    data(){
      return{
        categories: [
          {
            name: 'lifestyle & accessories'
          },
          {
            name: 'rhythm & sounds'
          },
          {
            name: 'wear & vesture'
          },
          {
            name: 'mood & lights'
          },
          {
            name: 'design & decor'
          },
        ],
      }
    },
    watch: {
      //eslint-disable-next-line no-unused-vars
      '$route.params.q' (to,from) {
          if(to != undefined && from != undefined){
              if(to != from){
                  this.$router.go()
                  // this.pageId = to
                  // this.getData()
              }
          }
      }
    },
    methods: {
        contactUs(){
            var user = "user"
            if(this.$store.state.profile.name){
                user = this.$store.state.profile.name
            }
            var text = "*Hey Team!*, \nI was browsing through the site and I came across something I need your help with.\nThanks , " + user
            text = encodeURI(text)
            
            window.open(`https://api.whatsapp.com/send?phone=+919717129243&text=${text}`,'_blank')
        },
    }

}
</script>

<style scoped>
:root{
    --bg: white;
    --fore: black;
}

#footer {
  background: var(--bg);
  padding: 0 0 30px 0;
  color: var(--fore);
  font-size: 14px;
}

#footer .footer-top {
  background: var(--bg);
  padding: 60px 0 30px 0;
}

#footer .footer-top .footer-info {
  margin-bottom: 30px;
}

#footer .footer-top .footer-info h3 {
  font-family: var(--header-font);
  font-size: 28px;
  margin: 0 0 20px 0;
  padding: 2px 0 2px 0;
  line-height: 1;
  font-weight: 700;
  text-transform: uppercase;
}

#footer .footer-top .footer-info h3 img {
  width: 10%;
}

#footer .footer-top .footer-info p {
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 0;
  font-family: "Raleway", sans-serif;
  color: var(--fore);
}

#footer .footer-top .social-links a {
  font-size: 18px;
  display: inline-block;
  background: #ffc451;
  color: black;
  line-height: 1;
  padding: 8px 0;
  margin-right: 4px;
  border-radius: 4px;
  text-align: center;
  width: 36px;
  height: 36px;
  border: 2px solid #ffc451;
  transition: 0.3s;
}

#footer .footer-top .social-links a:hover {
  background: white;
  color: #151515;
  text-decoration: none;
}

#footer .footer-top h4 {
  font-size: 16px;
  font-weight: 600;
  color: var(--fore);
  position: relative;
  padding-bottom: 12px;
}

#footer .footer-top .footer-links {
  margin-bottom: 30px;
}

#footer .footer-top .footer-links ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

#footer .footer-top .footer-links ul i {
  padding-right: 2px;
  margin-right: 4px;
  color: #ffc451;
  font-size: 10px;
  line-height: 1;
}

#footer .footer-top .footer-links ul li {
  padding: 10px 0;
  display: flex;
  align-items: center;
}

#footer .footer-top .footer-links ul li:first-child {
  padding-top: 0;
}

#footer .footer-top .footer-links ul a {
  color: var(--fore);
  transition: 0.3s;
  display: inline-block;
  line-height: 1;
  text-transform: capitalize;
}

#footer .footer-top .footer-links ul a:hover {
  color: #ffc451;
}

#footer .copyright {
  text-align: center;
  padding-top: 30px;
}

#footer .credits {
  padding-top: 10px;
  text-align: center;
  font-size: 13px;
  color: #fff;
}

</style>