import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home'
import About from '../views/About'
import Profile from '../views/Profile/Profile'
import Overview from '../views/Profile/Overview'
import Admin from '../views/Admin'
import Orders from '../views/Profile/MyOrders'
import ProfileSetting from '../views/Profile/ProfileSetting'
import Checkout from '../views/Checkout'
import AllProducts from '../views/AllProducts'
import ProductPage from '../views/ProductPage'
import PrivacyPolicy from '../views/Footer/PrivacyPolicy'
import TermsAndConditions from '../views/Footer/TermsAndConditions'
import RefundPolicy from '../views/Footer/RefundPolicy'
import NotFound from '../views/NotFound'
import FAQ from '../views/FAQ'
import Test from '../views/Test'
import OrderPhones from '../views/OrderPhones'
//import Sitemap from '../../dist/sitemap.xml'
import CategoryProducts from '../views/CategoryProducts'

import {fb} from '../firebase'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/admin',
    name: 'admin',
    component: Admin,
    meta: {
      requiresAuth: true,
      admin: true,
    }
  },
  {
    path: '/profile',
    name: 'Profile',
    component: Profile,
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: 'overview',
        name: 'Overview',
        component: Overview
      },
      {
        path: 'orders',
        name: 'orders',
        component: Orders
      },
      {
        path: 'profile-setting',
        name: 'profile-setting',
        component: ProfileSetting
      },
    ]
  },
  {
    path: '/about',
    name: 'About',
    component: About
  },
  {
    path: '/products',
    name: 'allProducts',
    component: AllProducts,
  },
  {
    path: '/product/:id',
    name: 'productPage',
    component: ProductPage
  },
  {
    path: '/checkout',
    name: 'checkout',
    component: Checkout,
  },
  {
    path: '/privacy-policy',
    name: 'privacy-policy',
    component: PrivacyPolicy,
  },
  {
    path: '/terms-and-conditions',
    name: 'terms-and-conditions',
    component: TermsAndConditions,
  },
  {
    path: '/refund-policy',
    name: 'refund-policy',
    component: RefundPolicy,
  },
  {
    path: '/faq',
    name: 'faq',
    component: FAQ,
  },
  {
    path: '/:pathMatch(.*)*', 
    name: 'not-found', 
    component: NotFound
  },
  {
    path: '/test',
    name: 'test',
    component: Test
  },
  {
    path: '/order-phones',
    name: 'order-phones',
    component: OrderPhones
  },
  {
    path: '/category/:q',
    name: 'categoryProduct',
    component: CategoryProducts
  },
  // {
  //   path: '/sitemap',
  //   name: 'sitemap',
  //   component: Sitemap
  // },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  //eslint-disable-next-line no-unused-vars
  scrollBehavior (to, from, savedPosition) {
      return { top: 0 }
    }
})

router.beforeEach((to,from,next) => {
  const requiresAuth = to.matched.some(x => x.meta.requiresAuth)
  const admin = to.matched.some(x=> x.meta.admin)
  const currentUser = fb.auth().currentUser

  if (requiresAuth && !currentUser){
    next('/')
  }else if (requiresAuth && currentUser){
    if(currentUser.uid == "mmQQeHBzMkSO1mxmycAD1xDYlPJ3" && admin){
      next()
    }else if(currentUser.uid != "mmQQeHBzMkSO1mxmycAD1xDYlPJ3" && admin){
      next('not-found')
    }else{
      next()
    }
  }else {
    next()
  }
})

export default router
