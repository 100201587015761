<template>
    <Navbar />
    <section id="all-products" class="all-products">
      <div class="container" data-aos="fade-up">

        <div class="section-title">
          <p>Order Phones</p>
        </div>
        <div class="row">
          <ol>
            <li v-for="product in products" :key="product.index" class="product-card col-xl-4 col-lg-4 col-md-4 col-6">
              <a :href="product.data().link" target="_blank">{{product.data().name}}</a>
            </li>
          </ol>
        </div>

      </div>
    </section>
    <Footer />
</template>

<script>
import {db} from '../firebase'

export default {
  name: "orderPhones",
  components: {
      
  },
  data(){
    return {
        products: [],
        searchQuery: null,
    }
  },
  methods:{
    getProducts(){
      db.collection('phones').orderBy("name","asc").get()
          .then((querySnapshot) => {
              this.products = []
              querySnapshot.forEach((doc) => {
                  this.products.push(doc)
              })
          })
    }
  },
  created(){
    this.getProducts()
  },
  firestore(){
      return {
        products: db.collection('phones'),
      }
  },
};
</script>

<style scoped>
#all-products {
  background: var(--bg-color);
  padding-top: 74px;
  padding-bottom: 30px;
}

.section-title {
  padding-bottom: 40px;
  text-align: center;
}

.section-title p {
  margin: 0;
  font-size: 36px;
  font-weight: 700;
  text-transform: uppercase;
  color: #151515;
}

@media (max-width: 768px){
  .section-title p {
    font-size: 20px;
  }
}

</style>