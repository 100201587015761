<template>
    <Navbar />
    <section id="admin" class="admin">
        <div class="container">
            <h1>Admin Panel</h1>
            <div>
                <p><button @click="showModal(1)" class="btn btn-primary">All Products</button></p>
                <p><button @click="showModal(2)" class="btn btn-success">All Orders</button></p>
                <p><button @click="showModal(3)" class="btn btn-success">All Phones</button></p>
            </div>
            <AllProducts v-if="verified == true && selectedButton == 1" />
            <AllOrders v-if="verified == true && selectedButton == 2" />
            <AllPhones v-if="verified == true && selectedButton == 3" />
            <div class="modal fade" id="verify" tabindex="-1" role="dialog" aria-labelledby="editLabel" aria-hidden="true">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="editLabel">Verify</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <div class="form-group">
                                <label for="exampleInputPassword1">Password</label>
                                <input type="password" @keyup.enter="verify" v-model="password" class="form-control" id="exampleInputPassword1" placeholder="Password">
                            </div>
                        </div>

                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                            <button @click="verify" type="button" class="btn btn-primary">Submit</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <Footer />
</template>

<script>
import AllProducts from '../sections/Admin/AllProducts'
import AllOrders from '../sections/Admin/AllOrders'
import AllPhones from '../sections/Admin/AllPhones'
export default {
    name: 'admin',
    components: {
        AllProducts,
        AllOrders,
        AllPhones
    },
    data(){
        return{
            selectedButton: null,
            password: null,
            verified: false,
        }
    },
    watch: {
      //eslint-disable-next-line no-unused-vars
      '$route' (to,from) {
        window.$('#verify').modal('hide')
        // this.pageId = to
        // this.getData()
      }
    },
    methods: {
        showModal(id){
            this.verified = false
            this.selectedButton = id
            window.$('#verify').modal('show')
        },
        verify(){
            if(this.password == 'Adzpk1998A'){
                this.verified = true
                window.$('#verify').modal('hide')
            }else{
                this.$router.push('/')
            }
            this.password = null
        }
    }
}
</script>

<style scoped>
#admin {
  background: var(--bg-color);
}

#admin .container {
  padding-top: 74px;
  position: relative;
  padding-bottom: 40px;
  text-align: center;
}
</style>