<template>
    <section id="why-choose-us" class="why-choose-us">
      <div class="container" data-aos="fade-up">

        <div class="section-title">
          <p>Why Choose Us</p>
        </div>

        <div class="row">

          <div class="col-lg-6 col-md-6 d-flex align-items-stretch">
            <div class="member" data-aos="fade-up" data-aos-delay="100">
              <div class="member-info">
                <h4><i class="fa fa-rocket"></i> Fast and Free Shipping</h4>
              </div>
            </div>
          </div>

          <div class="col-lg-6 col-md-6 d-flex align-items-stretch">
            <div class="member" data-aos="fade-up" data-aos-delay="200">
              <div class="member-info">
                <h4><i class="fa fa-book"></i>  Unique and Ever upgrading Catalogue</h4>
              </div>
            </div>
          </div>

          <div class="col-lg-6 col-md-6 d-flex align-items-stretch">
            <div class="member" data-aos="fade-up" data-aos-delay="300">               
              <div class="member-info">
                <h4><i class="fa fa-tags"></i> Best Prices</h4>
              </div>
            </div>
          </div>

          <div class="col-lg-6 col-md-6 d-flex align-items-stretch">
            <div class="member" data-aos="fade-up" data-aos-delay="400">
              <div class="member-info">
                <h4><i class="fa fa-undo"></i> COD available with Easy Returns</h4>
              </div>
            </div>
          </div>

        </div>

      </div>
    </section>
</template>

<script>
export default {
    name: 'ourteam',

}
</script>

<style scoped>
#why-choose-us {
  padding: 60px 0;
  background: url("../../public/img/mob-wcu-bg.jpeg") no-repeat;
  background-position: center center;
  background-size: cover;
  position: relative;
}

.section-title {
    color: #fefefe;
    padding-bottom: 40px;
    text-align: center;
}

.section-title p {
  margin: 0;
  font-size: 20px;
  font-weight: 700;
  text-transform: uppercase;
}

.section-title h2::after {
    content: "";
    width: 150px;
    height: 1px;
    display: inline-block;
    background: #ffde9e;
    margin: 4px 10px;
}

#why-choose-us .member {
  margin-bottom: 20px;
  overflow: hidden;
  border-radius: 5px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
  width: inherit;
}

#why-choose-us .member .member-info {
  padding: 25px 15px;
}

#why-choose-us .member .member-info h4 {
  font-weight: 700;
  margin-bottom: 5px;
  font-size: 15px;
  color: #ffffff;
}

#why-choose-us .member .member-info h4 i {
    margin-right: 10px;
    color: #f53f4b;
}

@media (min-width: 1024px) {
  #why-choose-us {
    background: url("../../public/img/wcu-bg.jpeg") no-repeat ;
    background-position: center center;
    background-size: cover;
    position: relative;
    background-attachment: fixed;
  }

  .section-title p {
    font-size: 36px;
  }

  #why-choose-us .member .member-info h4 {
    font-size: 18px;
  }
}

</style>