<template>
    <section id="overview" class="overview">
        <div class="container">
            <div class="details">
                <img src="../../../public/img/user.png" alt="" class="profile-pic">
                <h1 class="heading">{{ $store.state.profile.name }}</h1>
                
                <p class="mail"><i class="fa fa-envelope" aria-hidden="true"></i>{{ email }}</p>
                
                <div class="stats">
                    <div class="col-6">
                        <h4>{{$store.state.profile.orderCount}}</h4>
                        <p>Orders</p>
                    </div>
                    <div class="col-6" v-if="!uVerified">
                        <h4>Pending</h4>
                        <p>Verification</p>
                    </div>
                    <div class="col-6" v-if="uVerified">
                        <h4>Done</h4>
                        <p>Verification</p>
                    </div>
                </div>
            </div>
            <div class="row mt-5 justify-content-center">
                <div class="col-lg-8">
                    <div class="address-details">
                        <div class="header">
                            Address Details
                            <!--<span><router-link to="/admin/profile" class="edit-btn">Edit</router-link></span>-->
                        </div>
                        <div class="p-2 pt-4 p-lg-4">
                            <p class="font-italic mb-2" v-if="!uVerified">
                                Please Verify E-Mail to order products.
                                <button @click="verifyUser" class="verify-btn btn">Send Verification Mail</button>
                            </p>
                            <ul class="list-unstyled mb-2">
                                <li><strong class="text-muted">Name </strong><strong>{{ $store.state.profile.name }}</strong></li>
                                <li><strong class="text-muted">E-Mail </strong><strong>{{ email }}</strong></li>
                                <li><strong class="text-muted">Phone Number (+91)</strong><strong> {{$store.state.profile.phone}}</strong></li>
                                <li><strong class="text-muted">Address </strong><strong>{{$store.state.profile.address}}</strong></li>
                                <li><strong class="text-muted">Postal Code</strong><strong>{{$store.state.profile.postCode}}</strong></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import {fb} from '../../firebase'

export default {
    name: 'overview',
    components: {
        
    },
    data(){
        return{
            uVerified: false,
            email: null,
        }
    },
    methods: {
        userUnverified(){
            if(!this.uVerified){
                window.Toast.fire({
                    icon: 'warning',
                    title: 'Please Verify E-Mail to order products.'
                })
            }
        },
        verifyUser(){
            fb.auth().currentUser.sendEmailVerification()
                .then(() => {
                    window.Toast.fire({
                        icon: 'success',
                        title: 'Verification E-Mail sent'
                    })
                }).catch((error) => {
                    console.log(error)
                })
        }

    },
    created(){
        const user = fb.auth().currentUser
        this.uVerified = user.emailVerified
        this.email = user.email
        this.userUnverified()
    }
}
</script>

<style scoped>
#overview {
    background: linear-gradient(to top, #ffdd00, #fbb034);
}

#overview .container {
  position: relative;
  padding-top: 74px;
  color: white;
  min-height: 60vh;
  text-align: center;
}

#overview .details .profile-pic {
  height: 6rem;
  width: 6rem;
  object-fit: center;
  border-radius: 50%;
  border: 2px solid #fff;
  margin-top: 2rem;
}

#overview .details .heading {
  font-weight: 400;
  font-size: 1.7rem;
  margin: 1rem 0;
}

#overview .details .mail {
  display: inline-block;
  font-size: 1rem;
}

#overview .details .mail i {
    margin-right: 0.5rem;
}

.stats {
  display: flex;
  margin-top: 4rem;
  font-size: 1.5rem;
}

#overview .address-details {
    background: white;
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.4);
    border-radius: 2%;
    margin-bottom: 20px;
    color: black;
}

#overview .address-details .header {
    padding: 1rem 1.5rem;
    font-weight: 700;
    text-transform: uppercase;
    border-radius: 1rem;
    background-color: #f8f9fa
}

#overview .address-details .header span {
    float: right;
}

#overview .address-details .edit-btn {
    padding: 0.5rem;
    background: #343a40;
    border-radius: 50rem;
    text-decoration: none;
    color: white;
    font-weight: 400;
}

#overview .address-details .verify-btn {
    padding: 0 0.5rem;
    border-radius: 50rem;
    background: yellow;
    border: 1px solid black;
    font-size: 0.9rem;
}

#overview .address-details li {
    display: flex;
    justify-content: space-between;
    padding: 1rem 0;
    border-bottom: 1px solid #dee2e6;
}

</style>