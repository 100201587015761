<template>
    <Navbar />
    <section id="hero" class="d-flex align-items-center justify-content-center">
        <div class="container" data-aos="fade-up">

            <div class="row justify-content-center" data-aos="fade-up" data-aos-delay="150">
                <div class="col-xl-6 col-lg-8">
                    <h1>404</h1>
                    <h2>UH OH! You're lost.</h2>
                    <h3>The page you are looking for does not exist. How you got here is a mystery. But you can click the button below to go back to the homepage.</h3>
                    <router-link to="/"><i class="fa fa-home"></i> Home</router-link>
                </div>
            </div>

        </div>
    </section>
    <Footer />
</template>

<script>

export default {
    name : 'hero',
    components: {
      
    }
}
</script>

<style scoped>
#hero {
  width: 100%;
  height: 100vh;
  background: var(--bg-color);
  background-size: cover;
  position: relative;
}

#hero:before {
  content: "";
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
}

#hero .container {
  position: relative;
  padding: 74px 0;
  text-align: center;
}

#hero .row {
    margin-right: 0px;
}

#hero h1 {
  margin: 0;
  font-size: 100px;
  font-weight: 700;
  line-height: 64px;
  color: black;
  font-family: "Poppins", sans-serif;
}

#hero h2 {
  color: rgba(0, 0, 0, 0.8);
  margin: 20px 0 0 0;
  font-size: 40px;
}

#hero h3 {
    color: rgba(0, 0, 0, 0.7);
    margin: 20px 0 30px 0;
    font-size: 25px;
    line-height: 25px;
}

#hero a {
    background: #ffc451;
    padding: 8px 20px;
    border-radius: 4px;
    color: black;
    border: 2px solid #ffc451 ;
}

#hero a:hover {
    transition: ease-in-out 0.3s;
    background: white;
    text-decoration: none;
}

@media (max-width: 768px) {
  #hero {
    height: auto;
  }
  #hero h1 {
    font-size: 100px;
    line-height: 36px;
    margin: 60px 0 100px 0;
  }
  #hero h2 {
    font-size: 40px;
    line-height: 24px;
    margin-top: 10px;
    margin-bottom: 20px;
  }
  #hero h3 {
      font-size: 25px;
      line-height: 25px;
  }
}
</style>