<template>
  <Navbar />
  <section id="product" class="product">
      <div class="container">
          <div class="mob-header d-lg-none">
              <h2>{{product.name}}</h2>
              <p>&#8377;{{product.price}} <span class="mrp">&#8377;{{product.mrp}}</span></p>
              <ul class='rating-stars'>
                <li :key="index" v-for="index in 5">
                    <i class="fa" :class="avgRating >= index ? 'fa-star' : 'fa-star-o'"></i>
                </li>
              </ul>
          </div>

          <div class="row mt-3">
            <div class="image col-lg-6">
                <div class="product-carousel">
                    <swiper @slideChange="onSwipe()" :autoplay='{"delay": 2500,"disableOnInteraction": false}' ref="mySwiper" class="mySwiper">
                        <swiper-slide :key="index" v-for="(image,index) in product.images">
                            <img :src="selectedImage == image ? selectedImage : image" alt="">
                        </swiper-slide>
                    </swiper>
                    <!--<img :src="selectedImage" class="img-fluid" alt="">-->
                </div>

                <div class="thumbnail">
                    <img :key="index" v-for="(image,index) in product.images" 
                          @click="selectImage(image,index)" 
                          :class="selectedImage == image ? 'active' : ''" 
                          :src="image" class="img-fluid" alt="">
                </div>
            </div>

            <div class="info col-lg-6">
                <div class="web-header d-none d-lg-block">
                    <h2>{{product.name}}</h2>
                    <p>&#8377;{{product.price}} <span> &#8377;{{product.mrp}}</span></p>
                    <ul class='rating-stars'>
                        <li :key="index" v-for="index in 5">
                            <i class="fa" :class="avgRating >= index ? 'fa-star' : 'fa-star-o'"></i>
                        </li>
                    </ul>
                </div>

                <div class="mob-sub-info d-lg-none">
                    <p class="sizes" v-if="product.sizes.length">
                        <span @click="selectedSize = size" :key="index" v-for="(size,index) in product.sizes" :class="selectedSize == size ? 'active' : ''">{{size}}</span>
                    </p>
                    <p class="colors" v-if="product.colors.length">
                        <span @click="selectedColor = color" :key="index" v-for="(color,index) in product.colors" :style="{'background': color}" :class="selectedColor == color ? 'active' : ''"></span>
                    </p>
                    <AddToCart v-if="product.name != null"
                        :image="product.images[0]"
                        :pId="pageId"
                        :price="product.price"
                        :name="product.name"
                        :color="selectedColor"
                        :size="selectedSize"
                        :disable="product.stock == 0">
                    </AddToCart>
                    <div class="stock-det">
                        <p class="times-ordered">Ordered 
                            <span>
                                <ul>
                                    <li :key="index" v-for="(digit,index) in orderedTimesArray">{{digit}}</li>
                                </ul>
                            </span> 
                            times
                        </p>
                        <p v-if="product.stock >= 10" class="in-stock">In Stock</p>
                        <p v-if="product.stock < 10 && product.stock > 0" class="less-stock">Only {{product.stock}} units left</p>
                        <p v-if="product.stock == 0" class="out-stock">Out of Stock</p>
                    </div>
                </div>

                <div class="tag-line">
                    <p>{{product.tagLine}}</p>
                </div>

                <div class="web-sub-info d-none d-lg-block">
                    <p class="sizes" v-if="product.sizes.length">
                        <span @click="selectedSize = size" :key="index" v-for="(size,index) in product.sizes" :class="selectedSize == size ? 'active' : ''">{{size}}</span>
                    </p>
                    <p class="colors" v-if="product.colors.length">
                        <span @click="selectedColor = color" :key="index" v-for="(color,index) in product.colors" :style="{'background': color}" :class="selectedColor == color ? 'active' : ''"></span>
                    </p>
                    <AddToCart v-if="product.name != null"
                        :image="product.images[0]"
                        :pId="pageId"
                        :price="product.price"
                        :name="product.name"
                        :color="selectedColor"
                        :size="selectedSize"
                        :disable="product.stock == 0">
                    </AddToCart>
                    <div class="stock-det">
                        <p class="times-ordered">Ordered 
                            <span>
                                <ul>
                                    <li :key="index" v-for="(digit,index) in orderedTimesArray">{{digit}}</li>
                                </ul>
                            </span> 
                            times
                        </p>
                        <p v-if="product.stock >= 10" class="in-stock">In Stock</p>
                        <p v-if="product.stock < 10 && product.stock > 0" class="less-stock">Only {{product.stock}} units left</p>
                        <p v-if="product.stock == 0" class="out-stock">Out of Stock</p>
                    </div>
                </div>

                <div class="pointers">
                    <p @click="pointer = !pointer">Product Details <span class="float-right"><i class="fa" :class="!pointer ? 'fa-plus' : 'fa-minus'"></i></span></p>
                    <transition name="slide-fade">
                        <ul v-if="pointer"> 
                            <li v-for="(point,index) in product.pointers" :key="index">{{point}}</li>
                        </ul>
                    </transition>
                </div>

            </div>

            <div class="description">
                <h3>{{product.headerLine}}</h3>
                <img :src="product.images[1]" class="img-fluid col-lg-5" alt="">
                <img :src="product.images[2]" class="img-fluid col-lg-5" alt="">
                <div class="long-desc row justify-content-center">
                    <div class="col-lg-10">
                        <p v-html="product.description"></p>
                        <div class="social">
                            <button v-if="shareSupported" @click="share" class="btn" title="Share"><i class="fa fa-share-alt"></i></button>
                        </div>
                    </div>
                </div>
            </div>

            <div class="reviews">
                <div class="review-header">
                    <span @click="active = 1" :class="active == 1 ? 'active' : '' ">Reviews({{totalReviews}})</span>
                    <span @click="active = 2" :class="active == 2 ? 'active' : '' ">FAQs</span>
                </div>
                <transition name="slide-fade-RF">
                    <Review @total-stars="calcRating" v-if="active == 1" :pId="pageId" />
                </transition>
                <transition name="slide-fade-RF">
                    <div class="faqs" v-if="active == 2">
                        <p class="font-italic mb-4">For any query, feel free  to contact us via whatsapp</p>
                        <div :key="index" v-for="(faq,index) in product.faqs" class="faq">
                            <p class="question">{{faq.question}}</p>
                            <p class="answer">{{faq.answer}}</p>
                        </div>
                    </div>
                </transition>
            </div>
          </div>
      </div>
  </section>
  <Recommended v-if="product.name != null"
    :category="product.categories"
    :pId="pageId">
  </Recommended>
  <PromotionBanner />
  <Footer />
</template>

<script>
import {db} from '../firebase'
import Review from '../components/Review'
import Recommended  from '../sections/Recommended'
import PromotionBanner from '../sections/PromotionBanner'
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/swiper.scss';
import SwiperCore, {Autoplay} from 'swiper/core';

SwiperCore.use(Autoplay);

export default {
    name: 'productPage',
    components: {
        Review,
        Recommended,
        PromotionBanner,
        Swiper,
        SwiperSlide,
    },
    data(){
      return{
        pageId: this.$route.params.id,
        selectedImage: null,
        product: {
            name: null,
            tagLine: null,
            description: null,
            mrp: null,
            price: null,
            stock: null,
            timesOrdered: null,
            bestSeller: false,
            headerLine: null,
            categories: [],
            sizes: [],
            colors: [],
            pointers: [],
            images: [],
            faqs: [],
        },
        pointer: false,
        active: 1,
        selectedColor: null,
        selectedSize: null,
        orderedTimesArray: [],
        avgRating: null,
        totalReviews: null,
      }
    },
    watch: {
        //eslint-disable-next-line no-unused-vars
        '$route.params.id' (to,from) {
            if(to != undefined){
                if(to != this.pageId){
                    this.$router.go()
                    // this.pageId = to
                    // this.getData()
                }
            }
        }
    },
    computed: {
        shareSupported() {
            return navigator.share
        }
    },
    methods: {
        selectImage(image,i){
            this.selectedImage = image
            this.$refs.mySwiper.$el.swiper.slideTo(i)
        },
        onSwipe(){
            const i = this.$refs.mySwiper.$el.swiper.activeIndex
            this.selectedImage = this.product.images[i]
        },
        share(){
            navigator.share({
                title: 'Share Product',
                text: this.product.name,
                url: this.pageId
            })
        },
        calcRating(stars,reviews){
            this.totalReviews = reviews
            this.avgRating = Math.ceil(stars/reviews)
            
            if (isNaN(this.avgRating)){
                this.avgRating = 5
            }
        },
        pageChanged(){
            this.pageId = this.$route.params.id
            this.getData()
        },
        getData(){
            db.collection('products').doc(this.pageId).get()
                .then((querySnapshot) => {
                    this.product = querySnapshot.data()
                    this.selectedImage = this.product.images[0]
                    this.selectedSize = this.product.sizes[0]
                    this.selectedColor = this.product.colors[0]
                    this.orderedTimesArray = this.product.timesOrdered.split("")
                })
                .catch((error) => {
                    console.log(error)
                })
        },
        whatsapp(){
            var link = "https://e-commerece-2cc42.web.app/product/" + this.pageId
            var text = this.product.name
            text = encodeURI(text)
            text = text + " " + link
            
            window.open(`https://api.whatsapp.com/send?text=${text}`,'_blank')
        },
    },
    created(){
      this.getData()
    }
}
</script>

<style scoped>
#product {
  background: var(--bg-color);
}

#product .container {
  padding-top: 74px;
  position: relative;
  padding-bottom: 1px;
}

#product .rating-stars{
    display: flex;
    list-style: none;
    padding: 0;
}

#product .rating-stars li {
    margin: 0 2px;
    color: #f3d23e !important;
}

#product .mob-header h2 {
    font-size: 21px;
}

#product .mob-header p {
    font-size: 20px;
    margin: 0;
    font-weight: bold;
    float: right;
    color: #e50f0f;
}

#product .mob-header .mrp {
    text-decoration: line-through;
    font-size: 15px;
    opacity: 0.6;
    color: black;
}

#product .product-carousel {
  position: relative;
  z-index: 1;
  max-width: 61rem;
}

#product .product-carousel img {
    width: 100%;
}

#product .thumbnail{
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
  margin-right: -1rem;
  margin-left: -0.5rem;
  padding-top: 1rem;
}

#product .thumbnail img{
    display: block;
    position: relative;
    width: 5rem;
    height: 4rem;
    margin: .3rem;
    transition: border 0.2s ease-in-out;
    border: 1px solid #e3e9ef;
    border-radius: .3125rem;
    text-decoration: none !important;
    overflow: hidden;
    opacity: 0.6;
    border: solid 1px #aaa;
}

#product .thumbnail .active{
  opacity: 1;
  border: solid 1px black;
}

#product .thumbnail img:hover{
  cursor: pointer;
  border: 1px solid black;
}

#product .info .web-header h2 {
    font-size: 25px;
}

#product .info .web-header p {
    font-size: 18px;
    font-weight: bold;
    float: right;
    color: #e50f0f;
}

#product .info .web-header span {
    font-size: 16px;
    opacity: 0.6;
    text-decoration: line-through;
    color: black;
}

#product .info .mob-sub-info {
    text-align: center;
}

#product .info .mob-sub-info .sizes {
    padding-top: 20px;
}

#product .info .stock-det {
    text-align: center;
}

#product .info .times-ordered {
  font-weight: bold;
  padding: 0 0.5rem;
  border-radius: 1rem;
  margin-top: 0.5rem;

}

#product .info .times-ordered span {
  display: inline-block;
}

#product .info .times-ordered ul {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
}

#product .info .times-ordered li {
  background: white;
  border: 1px solid black;
  padding: 1px 4px;
  margin: 0 2px;
}

#product .info .in-stock {
  color: limegreen;
  font-weight: bold;
  padding: 0 0.5rem;
  border-radius: 1rem;
  margin-top: 0.5rem;
}

#product .info .less-stock {
  color: red;
  font-weight: bold;
  padding: 0 0.5rem;
  border-radius: 1rem;
  margin-top: 0.5rem;
}

#product .info .out-stock {
  color: red;
  font-weight: bold;
  padding: 0 0.5rem;
  border-radius: 1rem;
  margin-top: 0.5rem;
}

#product .info .tag-line {
    padding-top: 18px;
}

#product .web-sub-info {
    text-align: center;
    padding-top: 10px;
}

#product .info .sizes span {
  padding: 0.5rem 0.9rem;
  background: black;
  border-radius: 5px;
  margin: 0 0.3rem;
  border: 1px solid #e3e9ef;
  color: white;
}

#product .info .sizes span:hover {
  border: 1px solid black;
  cursor: pointer;
}

#product .info .sizes .active {
  border: 1px solid black;
  background: white;
  color: black;
}

#product .info .colors {
    padding-top: 15px;
}

#product .info .colors span {
  padding: 0.5rem 1.1rem;
  border-radius: 5px;
  margin: 0 0.4rem;
  border: 1px solid #e3e9ef;
  opacity: 0.4;
}

#product .info .colors span:hover {
  border: 1px solid black;
  cursor: pointer;
}

#product .info .colors .active {
  opacity: 1;
  border: 1px solid black;
}

#product .info .pointers {
    border-top: 1px solid #aaa;
    border-bottom: 1px solid #aaa;
    padding: 15px;
    margin-top: 25px;
}

#product .info .pointers i {
    font-size: 14px;
}

#product .info .pointers i:hover {
    cursor: pointer;
}

#product .info .pointers p {
    margin: 0;
    font-size: 20px;
}

#product .info .pointers p:hover {
    cursor: pointer;
}

#product .info .pointers ul {
    margin-top: 10px;
}

#product .description {
    margin-top: 6rem;
    text-align: center;
}

#product .description h3 {
    margin-bottom: 2rem;
}

#product .description img {
    margin-bottom: 30px;
}

#product .description .long-desc {
    margin: 0;
}

#product .description .p {
    margin-top: 6rem;
}

#product .description .social button {
  font-size: 18px;
  display: inline-block;
  background: #ffc451;
  color: black;
  line-height: 1;
  padding: 8px 0;
  margin-right: 4px;
  border-radius: 4px;
  text-align: center;
  width: 36px;
  height: 36px;
  border: 2px solid #ffc451;
  transition: 0.3s;
}

#product .description .social button:hover {
  background: white;
  color: #151515;
  text-decoration: none;
}

#product .reviews {
    padding: 20px 20px 15px 20px;
    font-size: 25px;
    width: 100%;
}

#product .reviews .review-header {
    border-bottom: 1px solid #aaa;
    padding-bottom: 15px;
    margin-bottom: 10px;
}

#product .reviews span {
    margin-right: 50px;
    padding-bottom: 19px;
}

#product .reviews .active {
    border-bottom: 2px solid black;
    font-weight: bold;
}

#product .reviews span:hover{
    font-weight: bold;
    cursor: pointer;
}

#product .reviews .faqs {
    font-size: 15px;
    padding-left: 20px;
}

#product .reviews .faq {
  border-bottom: 1px solid rgba(0,0,0,0.3);
  margin-top: 0.5rem;
}

#product .reviews .question {
  margin: 0.1rem 0;
  font-weight: bold;
}

#product .reviews .answer {
  margin-bottom: 0.5rem;
}

@media (max-width: 992px) {
    #product .description {
        margin-top: 4rem;
    }
    #product .reviews {
        padding: 20px 5px 15px 5px;
    }
    #product .thumbnail img {
        width: 3rem;
        height: 3rem;
    }
}

.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.2s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateY(-20px);
  opacity: 0;
}

.slide-fade-RF-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-RF-leave-active {
  transition: all 0.2s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-RF-enter-from,
.slide-fade-RF-leave-to {
  transform: translateX(20px);
  opacity: 0;
}

</style>