<template>
    <div class="AddToCart">
        <button @click="addToCart" :disabled="disabled" class="btn"><i class="fa fa-shopping-cart pr-2"></i>Add To Cart</button>
    </div>
</template>

<script>
export default {
    name: 'AddToCart',
    props: {
        name: String,
        price: String,
        pId: String,
        image: String,
        color: {
            type: String,
            default: null,
        },
        size: {
            type: String,
            default: null,
        },
        disable: Boolean,
    },
    data(){
        return{
            item: {
                productName: this.name,
                productPrice: this.price,
                productId: this.pId,
                productImage: this.image,
                productQuantity: 1,
                productColor: this.color,
                productSize: this.size,
            },
            disabled: this.disable,
        }
    },
    methods: {
        addToCart(){
            this.item.productColor = this.color
            this.item.productSize = this.size
            
            window.$('#miniCart').modal('show')
            this.$store.commit('addToCart',this.item)
        },
    }
}
</script>

<style scoped>
.AddToCart{
    padding: 10px 30px;
}

.AddToCart button{
    background: #ffc451;
    color: black;
    transition: ease-in-out 0.3s;
    width: 100%;
    padding: 10px;
    border: 2px solid #ffc451;
}

.AddToCart button:hover{
    background: white;
    border: 2px solid #ffc451;
}

</style>