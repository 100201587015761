<template>
    <!-- <header v-scroll="handleScroll" id="header" class="fixed-top">-->
    <header id="header" class="fixed-top">
      <div class="container d-flex align-items-center justify-content-between">
        <h1 class="logo">
          <router-link to="/" class="navbar-brand">
            <img src="../../public/img/logo.jpeg" alt="" class="img-fluid">TRINIQUE
          </router-link>
        </h1>
        <!-- Mobile Nav -->
        <a class="btn mobile-cart-btn d-lg-none" data-toggle="modal" data-target="#miniCart">
            <i class="fa fa-cart-plus fa-lg">
              <span v-if="$store.getters.totalQuantity">{{$store.getters.totalQuantity}}</span>
            </i>
        </a>
        <button @click="toggleMenu" type="button" class="mobile-nav-toggle d-lg-none">
            <i class="fa" :class="navActive ? 'fa-times' : 'fa-bars'"></i>
        </button>
        <div class="mobile-nav-overly"></div>
        <nav v-if="navActive" class="mobile-nav">
          <ul>
            <li><router-link @click="toggleMenu" to="/products" class="nav-link">Products</router-link></li>
            <li class="drop-down">
              <router-link @click="toggleDropDown" to="" class="nav-link">
                Categories
                <i class="fa" :class="dropDownActive ? 'fa-chevron-up' : 'fa-chevron-down'"></i>
              </router-link>
              <ul :class="dropDownActive ? 'active' : ''">
                <li :key="index" v-for="(cat,index) in categories">
                  <router-link :to="{name: 'categoryProduct' , params: {q:encodeURI(cat.name)}}">{{cat.name}}</router-link>
                </li>
              </ul>
            </li>
            <li><router-link @click="toggleMenu" to="/faq" class="nav-link">FAQ</router-link></li>
            <!--<li><a href="#footer" class="nav-link">About Us</a></li>-->
          </ul>
          <router-link @click="toggleMenu" to="/profile/overview" v-if="$store.state.profile.name" class="btn get-started-btn scrollto">Hi, {{$store.state.profile.name}}</router-link>
          <a v-else @click="toggleMenu" class="btn get-started-btn scrollto" data-toggle="modal" data-target="#login">Get Started</a>
          <ul>
            <li><a href="tel: 9717129243"><i class="fa fa-phone mr-3"></i>: +91 9717129243</a></li>
            <li><a href="mailto: support@trinique.in"><i class="fa fa-envelope mr-3"></i>: support@trinique.in</a></li>
          </ul>   
        </nav>
        <!-- Desktop Nav -->
        <nav class="nav-menu d-none d-lg-block d-sm-none d-xs-none">
          <ul>
            <li><router-link to="/products" class="nav-link">Products</router-link></li>
            <li class="drop-down"><router-link to="" class="nav-link">Categories<i class="fa fa-chevron-down"></i></router-link>
              <ul>
                <li :key="index" v-for="(cat,index) in categories">
                  <router-link :to="{name: 'categoryProduct' , params: {q:encodeURI(cat.name)}}">{{cat.name}}</router-link>
                </li>
              </ul>
            </li>
            <li><router-link to="/faq" class="nav-link">FAQ</router-link></li>
            <!--<li><a href="#footer" class="nav-link">About Us</a></li>-->
          </ul>   
        </nav>
        <router-link to="/profile/overview" v-if="$store.state.profile.name" class="btn get-started-btn scrollto d-none d-lg-block desktop-gs-btn">Hi, {{$store.state.profile.name}}</router-link>
        <a v-else class="btn get-started-btn scrollto d-none d-lg-block desktop-gs-btn" data-toggle="modal" data-target="#login">Get Started</a>
        <a class="btn desktop-cart-btn d-none d-lg-block" data-toggle="modal" data-target="#miniCart">
            <i class="fa fa-cart-plus fa-2x">
              <span v-if="$store.getters.totalQuantity">{{$store.getters.totalQuantity}}</span>
            </i>
        </a>
        <!-- Desktop Nav ends -->
      </div>
    </header>
    <MiniCart />
    <Login />
</template>

<script>
//import {fb} from '../firebase'

export default {
  name: 'Navbar',
  components: {
    
  },
  data(){
    return{
        navActive: false,
        dropDownActive: false,
        categories: [
          {
            name: 'lifestyle & accessories'
          },
          {
            name: 'rhythm & sounds'
          },
          {
            name: 'wear & vesture'
          },
          {
            name: 'mood & lights'
          },
          {
            name: 'design & decor'
          },
        ],
    }
  },
  methods: {
    toggleDropDown(){
      this.dropDownActive = !this.dropDownActive
    },
    toggleMenu(){
        this.navActive = !this.navActive
        window.$('#header').toggleClass('mobile-nav-active');
        window.$('.mobile-nav-overly').toggle();
    },
    handleScroll() {
      console.log(window.scrollY)
      if (window.scrollY < 100) {
        console.log("scrolled")
        window.$('#header').addClass('header-scrolled')
      }
      return window.scrollY > 100
    },
  },
  created(){
    
  },
}
</script>

<style scoped lang="scss">

#header {
  transition: all 0.5s;
  z-index: 997;
  padding: 5px 0;
  background: white;
}

#header.header-scrolled, #header.header-inner-pages {
  background: rgba(0, 0, 0, 0.8);
}

#header .logo {
  font-family: var(--header-font);
  font-size: 32px;
  margin: 0;
  padding: 0;
  line-height: 1;
  font-weight: 700;
  letter-spacing: 2px;
  text-transform: uppercase;
}

#header .logo a {
  color: black;
}

#header .logo img {
  max-height: 40px;
}

/* Desktop Navigation */
.desktop-cart-btn {
  position: fixed;
  top: 14px;
  right: 55px;
  z-index: 9998;
  border: 0;
  background: none;
  padding: 3px 12px 3px 12px;
}

.desktop-cart-btn i {
  color: black;
}

.desktop-cart-btn i span {
  position: absolute;
  background: red;
  padding: 1px 5px;
  font-size: 17px;
  border-radius: 50%;
  color: white;
  top: 0;
  right: -2px;
}

.nav-menu ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.nav-menu > ul {
  display: flex;
}

.nav-menu > ul > li {
  position: relative;
  white-space: nowrap;
  padding: 10px 0 10px 28px;
}

.nav-menu a {
  display: block;
  position: relative;
  color: black;
  transition: 0.3s;
  font-size: 15px;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
}

.nav-menu a:hover, .nav-menu .active > a, .nav-menu li:hover > a {
  color: #ffc451;
}

.nav-menu .drop-down i {
  font-size: 11px;
  margin-left: 8px;
}

.nav-menu .drop-down ul {
  display: block;
  position: absolute;
  left: 14px;
  top: calc(100% + 30px);
  z-index: 99;
  opacity: 0;
  visibility: hidden;
  padding: 0;
  background: #fff;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
  transition: 0.3s;
}

.nav-menu .drop-down:hover > ul {
  opacity: 1;
  top: 100%;
  visibility: visible;
}

.nav-menu .drop-down li {
  min-width: 180px;
  position: relative;
}

.nav-menu .drop-down ul a {
  padding: 10px 20px;
  font-size: 14px;
  font-weight: 500;
  text-transform: capitalize;
  color: #151515;
}

.nav-menu .drop-down ul a:hover, .nav-menu .drop-down ul .active > a, .nav-menu .drop-down ul li:hover > a {
  color: #151515;
  background: #ffc451;
  text-decoration: none;
}

.nav-menu .drop-down .drop-down ul {
  top: 0;
  left: calc(100% - 30px);
}

.nav-menu .drop-down .drop-down:hover > ul {
  opacity: 1;
  top: 0;
  left: 100%;
}

.nav-menu .drop-down .drop-down > a {
  padding-right: 35px;
}

@media (min-width: 1200px) and (max-width: 1230px) {
  .desktop-cart-btn{
    right: 25px;
  }
}

@media (min-width: 990px) and (max-width: 1060px) {
  .desktop-cart-btn{
    right: 25px;
  }
}

/* Get Startet Button */
.desktop-gs-btn {
  margin: 0 50px 0 0;
}

.get-started-btn {
  color: black;
  border-radius: 4px;
  padding: 7px 25px 8px 25px;
  white-space: nowrap;
  transition: 0.3s;
  font-size: 14px;
  display: inline-block;
  background: #ffbb38;
  border: 2px solid #ffc451;
}

.get-started-btn:hover {
  background: white;
  color: #343a40;
}

@media (max-width: 768px) {
  .get-started-btn {
    margin: 0 48px 0 0;
    padding: 7px 20px 8px 20px;
  }
}

/* Mobile Navigation */
.mobile-cart-btn {
  position: absolute;
  top: 14px;
  right: 70px;
  z-index: 9998;
  border: 0;
  background: none;
}

.mobile-cart-btn i {
  color: black;
}

.mobile-cart-btn i span {
  position: absolute;
  background: red;
  padding: 1px 6px;
  font-size: 12px;
  border-radius: 50%;
  color: white;
  top: 2px;
  left: 4px;
}

.mobile-nav-toggle {
  position: absolute;
  top: 20px;
  right: 15px;
  z-index: 9998;
  border: 0;
  background: none;
  font-size: 24px;
  transition: all 0.4s;
  outline: none !important;
  line-height: 1;
  cursor: pointer;
  text-align: right;
  background: white;
  border: none;
}

.mobile-nav-toggle i {
  color: black;
}

.mobile-nav {
  position: fixed;
  top: 55px;
  right: 15px;
  bottom: 15px;
  left: 15px;
  z-index: 9999;
  overflow-y: auto;
  background: white;
  transition: ease-in-out 0.2s;
  opacity: 0;
  visibility: hidden;
  border-radius: 10px;
  padding: 10px 0;
}

.mobile-nav * {
  margin: 0;
  padding: 0;
  list-style: none;
}

.mobile-nav a {
  display: block;
  position: relative;
  color: #151515;
  padding: 10px 20px;
  font-weight: 500;
  outline: none;
  text-transform: capitalize;
}

.mobile-nav li:hover, .mobile-nav .active > a, .mobile-nav li:hover > a {
  color: #151515;
  text-decoration: none;
  background: #ffc451;
}

.mobile-nav-overly {
  width: 100%;
  height: 100%;
  z-index: 9997;
  top: 0;
  left: 0;
  position: fixed;
  background: rgba(0, 0, 0, 0.6);
  overflow: hidden;
  display: none;
  transition: ease-in-out 0.2s;
}

.mobile-nav-active {
  overflow: hidden;
}

.mobile-nav-active .mobile-nav {
  opacity: 1;
  visibility: visible;
}

.mobile-nav-active .mobile-nav-toggle i {
  color: black;
}

.mobile-nav .drop-down > a {
  padding-right: 35px;
}

.mobile-nav .drop-down i {
  font-size: 11px;
  float: right;
  padding: 5px;
}

.mobile-nav .drop-down ul {
  display: none;
  overflow: hidden;
}

.mobile-nav .drop-down .active {
  display: inherit;
}

.mobile-nav .drop-down li {
  padding-left: 20px;
}

</style>
