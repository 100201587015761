<template>
    <Navbar />
    <section id="faq" class="d-flex align-items-center justify-content-center">
        <div class="container" data-aos="fade-up">

            <div class="row justify-content-center" data-aos="fade-up" data-aos-delay="150">
                <div class="col-xl-6 col-lg-8 mb-3">
                    <h1>Asked Questions</h1>
                </div>
                <h3>Find all the frequently asked questions by our customers right here.</h3>
            </div>

            <div class="row mt-5 justify-content-center" data-aos="zoom-in" data-aos-delay="250">
                <div class="panel col-12" :key="index" v-for="(faq,index) in faqs">
                    <div class="icon-box">
                        <span></span>
                        <div class="coll-11 question">
                          <h3 @click="faq.visible = !faq.visible;">{{faq.question}}</h3>
                          <transition name="slide-fade">
                            <div class="answer-box" v-if="faq.visible">
                                <div class="answer">
                                    {{faq.answer}}
                                </div>
                            </div>
                          </transition>
                        </div>
                        <a class="col-1" @click="faq.visible = !faq.visible;">
                            <i class="fa fa-plus" :class="[faq.visible ? 'expanded' : '']"></i>
                        </a>
                    </div>
                </div>
            </div>

        </div>
    </section>
    <Footer />
</template>

<script>

export default {
    name : 'faq',
    components : {
      
    },
    data(){
        return{
            faqs: [
                {
                    question: 'What are the payment methods accepted?',
                    answer: 'Only CoD',
                    visible: false,
                },
                {
                    question: 'How much time does it take for a product to be delivered?',
                    answer: '3-5 business days',
                    visible: false,
                },
                {
                    question: 'How can I track my order?',
                    answer: 'After ordering a product, a “track your order” button shall appear which will help you to keep a track of your product.',
                    visible: false,
                },
                {
                    question: 'How to request for a return?',
                    answer: 'In case you want to request for a return, please contact us via email/whatsapp.',
                    visible: false,
                },
                {
                    question: 'What is the delivery charge?',
                    answer: 'Everything on the website has free delivery unless the delivery charge is specifically mentioned.',
                    visible: false,
                },
                {
                    question: 'What if there are still some confusions?',
                    answer: 'In case your question is still unanswered, feel free to contact us via mail/whatsapp.',
                    visible: false,
                },
            ]
        }
    }
}
</script>

<style scoped lang="scss">
#faq {
  width: 100%;
  padding-bottom: 30px;
  height: auto;
  background: var(--bg-color);
  background-size: cover;
  position: relative;
}

#faq:before {
  content: "";
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
}

#faq .container {
  position: relative;
  padding-top: 74px;
  text-align: center;
}

#faq h1 {
  margin: 0;
  font-size: 56px;
  font-weight: 700;
  line-height: 64px;
  color: black;
  font-family: "Poppins", sans-serif;
  font-family: var(--header-font);
}

#faq h3 {
  color: rgba(0, 0, 0, 0.9);
  margin: 10px 0 0 0;
  font-size: 24px;
}

#faq .panel {
    margin-bottom: 20px;
}

#faq .icon-box span {
    position: absolute;
    content: " ";
    top: 0;
    left: 0;
    height: 100%;
    width: 4px;
    background: #ffc451;
    margin-left: 14px;
}

#faq .icon-box {
  display: flex;
  padding: 15px 20px;
  transition: ease-in-out 0.3s;
  height: 100%;
  text-align: left;
  background: white;
  box-shadow: 1px 6px 5px #aaaaaa;
}

#faq .icon-box .question {
  width: 100%;
}

#faq .icon-box i {
    z-index: 1;
    font-size: 14px;
    transition: 0.3s ease-in;
    color: black;
    padding-top: 1rem;
}

.expanded {
    transform: rotate(45deg) translateX(5px);
}

#faq .icon-box h3 {
  font-weight: 700;
  margin: 10px 0 0 0;
  padding-bottom: 10px;
  line-height: 1;
  font-size: 18px;
  line-height: 26px;
}

#faq .icon-box h3 a {
  color: #fff;
  transition: ease-in-out 0.3s;
}

#faq .icon-box a:hover {
  color: #ffc451;
  cursor: pointer;
}

#faq .icon-box h3:hover {
  color: #ffc451;
  cursor: pointer;
}

#faq .icon-box .answer-box {
    background: white;
    color: black;
}

#faq .icon-box .answer {
    margin-top: 10px;
}

@media (min-width: 1024px) {
  #faq {
    background-attachment: fixed;
  }
}

@media (max-width: 768px) {
  #faq {
    height: auto;
  }
  #faq h1 {
    font-size: 28px;
    line-height: 36px;
  }
  #faq h3 {
    font-size: 20px;
    line-height: 24px;
  }
}

.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.2s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateY(-20px);
  opacity: 0;
}
</style>