<template>
    <section id="orders" class="d-flex align-items-center justify-content-center">
        <div class="container">

            <div class="row justify-content-center">
                <div class="col-xl-6 col-lg-8 mb-3">
                    <h1>My Orders</h1>
                </div>
            </div>
            <div class="empty-cart" v-if="orders.length == 0">
                <h1>Your havn't placed any orders yet</h1>
                <h1>Please add item(s) in your cart.</h1>
                <router-link to="/products" class="btn btn-success mt-4">Continue Shopping</router-link>
            </div>

            <h3 v-if="orders.length != 0">Total Orders ({{orders.length}})</h3>

            <div class="row mt-5 justify-content-center" v-if="orders.length != 0">
                <div class="order-box col-lg-6" :key="index" v-for="(order,index) in orders">
                    <div class="order-panel">
                        <div class="header">
                            <div class="row">
                                <span class="col-3 pl-0">Status <p>{{order.status}}</p></span>
                                <span class="col-6 px-1">Order no <p>{{order.orderId}}</p></span>
                                <span class="col-3 px-0">Placed<p>{{order.date}}</p></span>
                            </div>
                        </div>
                        <div class="p-2"> 
                            <p v-if="order.status == 'cancelled'" class="font-italic mb-3"><i class="fa fa-times-circle-o"></i> Cancelled </p>
                            <p v-else-if="order.status != 'delivered'" class="font-italic mb-3"><i class="fa fa-truck"></i> Estimated Delivery by {{order.deliveryDate}} </p>
                            <p v-else-if="order.status == 'delivered'" class="font-italic mb-3"><i class="fa fa-archive"></i> Delivered on {{order.deliveryDate}}</p>
                            <div class="icon-box col-12" :key="index" v-for="(item,index) in order.items">
                                <div class="inner-row row" :class="index == order.items.length-1 ? 'last-item' : ''">
                                    <div class="image col-3 col-lg-2">
                                        <router-link :to="{name: 'productPage' , params: {id: item.productId}}"><img :src="item.productImage" alt=""></router-link>
                                    </div>
                                    <div class="content col-9 col-lg-10">
                                        <router-link :to="{name: 'productPage' , params: {id: item.productId}}">{{item.productName}}</router-link>
                                        <p class="details" v-if="item.productColor || item.productSize">
                                            <span class="color" v-if="item.productColor">Color: {{item.productColor}} </span>
                                            <span class="size" v-if="item.productSize">Size: {{item.productSize}} </span>
                                        </p>
                                        <p class="quantity">Quantity: {{item.productQuantity}}
                                            <span class="price">&#8377;{{item.productPrice}}.00</span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="row footer">
                                <a class="col-6 order-btn" :href="`https://trinique.shiprocket.co/tracking/order/${order.orderId}`" target="_blank">Track Order</a>
                                <span class="col-6 price">&#8377;{{order.totalPrice}}.00</span> 
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </section>
</template>

<script>
import {fb,db} from '../../firebase'

export default {
    name : 'myOrders',
    components : {
        
    },
    data(){
        return{
            orders: [],
            user: fb.auth().currentUser,
            date: null,
        }
    },
    methods: {
        getOrders(){
            db.collection('profiles').doc(this.user.uid).collection('orders').orderBy("status","desc").get()
                .then((querySnapshot) => {
                    querySnapshot.forEach((doc) => {
                        this.orders.push(doc.data())
                    })
                    this.orderArray()
                }).catch((error) => {
                    console.log(error)
                })
        },
        orderArray(){
            this.orders.sort((a, b) => {
                a = a.date.split("/")
                b = b.date.split("/")
                var c = new Date(a[2],a[1],a[0]);
                var d = new Date(b[2],b[1],b[0]);
                return d-c;
            });
        },
    },
    created(){
        this.getOrders()
    },
}
</script>

<style scoped lang='scss'>
#orders {
  width: 100%;
  padding-bottom: 30px;
  height: auto;
  background: var(--bg-color);
  background-size: cover;
  position: relative;
  z-index: 1;
}

#orders:before {
  content: "";
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
}

#orders .container {
  position: relative;
  padding-top: 74px;
  text-align: center;
}

#orders h1 {
  margin: 0;
  font-size: 56px;
  font-weight: 700;
  line-height: 64px;
  color: black;
  font-family: "Poppins", sans-serif;
}

#orders h3 {
  color: rgba(0, 0, 0, 0.9);
  margin: 10px 0 0 0;
  font-size: 24px;
  text-align: left;
}

#orders .empty-cart{
    height: auto;
    padding: 90px 0;
}

#orders .order-box {
    margin-bottom: 30px;
}

#orders .order-panel {
    background: white;
    box-shadow: 1px 6px 5px #aaaaaa;
    border-radius: 2%;
}

#orders .order-panel .header {
    padding: 0.5rem 1.5rem;
    font-weight: 600;
    text-transform: uppercase;
    border-radius: 1rem;
    background-color: #f8f9fa
}

#orders .order-panel .header p {
    margin-bottom: 0;
}

#orders .icon-box {
    background: white;
    padding: 10px;
    padding-top: 0;
}

#orders .icon-box .inner-row {
    margin: 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

#orders .icon-box .last-item {
    border: none;
}

#orders .icon-box .image {
    padding: 0;
}

#orders .icon-box .image img {
    width: 100%;
}

#orders .icon-box .content {
    text-align: left;
}

#orders .icon-box .content a {
    color: black;
    text-decoration: none;
    font-size: 20px;
    font-weight: bold;
}

#orders .icon-box .content .details {
    margin-bottom: 0;
}

#orders .icon-box .content .color {
    margin-right: 15px;
    text-transform: capitalize;
}

#orders .icon-box .content .quantity {
    font-size: 17px;
}

#orders .icon-box .content .price {
    float: right;
    font-weight: bold;
}

#orders .order-panel .footer{
    margin: 0 5px;
    padding-top: 10px;
}

#orders .order-panel .footer .order-btn {
    padding: 0.5rem 0;
    border-radius: 50rem;
    display: block;
    width: 100%;
    color: black;
    background-color: #ffc451;
    border: none;
    text-align: center;
    font-size: 1rem;
    text-decoration: none;
}

#orders .order-panel .footer .price {
    padding: 0.5rem 0;
    font-size: 1.2rem;
    font-weight: 700;
}

@media (min-width: 1024px) {
  #orders {
    background-attachment: fixed;
  }
}

@media (max-width: 768px) {
  #orders {
    height: auto;
  }
  #orders h1 {
    font-size: 28px;
    line-height: 36px;
  }
  #orders h3 {
    font-size: 20px;
    line-height: 24px;
  }
  #orders .icon-box {
      padding: 3px 5px;
  }
  #orders .icon-box .inner-row{
      padding-bottom: 3px;
  }
  #orders .icon-box .content a {
      font-size: 15px;
  }
  #orders .icon-box .content .quantity {
      font-size: 15px;
  }
}
</style>