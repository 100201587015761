import { createStore } from '../node_modules/vuex'
//eslint-disable-next-line no-unused-vars
var privateLocalStorage = window.localStorage

var cart = privateLocalStorage.getItem('__trinc__')
var profile = privateLocalStorage.getItem('__trinp__') ? JSON.parse(privateLocalStorage.getItem('__trinp__')) : {}

const store = createStore({
    state () {
      return {
        cart: cart ? JSON.parse(cart) : [],
        profile: {
          name: profile.name ? profile.name : null,
          email: profile.email ? profile.email : null,
          phone:profile.phone ? profile.phone : null,
          address:profile.address ? profile.address : null,
          postCode:profile.postCode ? profile.postCode : null,
          picture:profile.picture ? profile.picture : null,
          orderCount:profile.orderCount ? profile.orderCount : null,
        },
      }
    },
    getters: {
      totalPrice: state => {
        var total = 0;
        state.cart.filter((item) => {
            total += (item.productPrice * item.productQuantity);
        });

        return total;
      },
      totalQuantity: state => {
        var total = 0;
        state.cart.filter((item) => {
            total += item.productQuantity;
        });

        return total;
      },
      totalProductPrice: (state) => (id) => {
        var total = 0
        var found = state.cart.find(product => product.productId == id)
        
        total = found.productPrice * found.productQuantity

        return total
      }
    },
    mutations: {
        emptyCart(state){
          state.cart = []

          this.commit('saveData')
        },
        addToCart(state,item){
          var found = state.cart.find(product => product.productId == item.productId)
          console.log(found)
          if(found){
            found.productQuantity++
          }else{
            state.cart.push(item);
          }
          console.log(state.cart)
          this.commit('saveData')
        },
        saveData(state){
          privateLocalStorage.setItem('__trinc__',JSON.stringify(state.cart))
        },
        removeFromCart(state,item){
          var index = state.cart.indexOf(item)

          state.cart.splice(index,1)

          this.commit('saveData')
        },
        addUser(state,profile){
          state.profile = profile
          this.commit('saveUserData')
        },
        saveUserData(state){
          privateLocalStorage.setItem('__trinp__',JSON.stringify(state.profile) )
        },
        removeUserData(state){
          state.profile = {
            name:null,
            email:null,
            phone:null,
            address:null,
            postCode:null,
            picture:null,
            orderCount:null,
          }

          this.commit('saveUserData')
        },
        increaseItemQuantity(state,id){
          var found = state.cart.find(product => product.productId == id)
          
          if(found.productQuantity < 10){
            found.productQuantity++
            this.commit('saveData')
          }
        },
        decreaseItemQuantity(state,id){
          var found = state.cart.find(product => product.productId == id)
          
          if(found.productQuantity > 1){
            found.productQuantity--
            this.commit('saveData')
          } 
        }
    }
  })

  export default store