<template>
    <section id="category" class="d-flex align-items-center justify-content-center">
        <div class="container" data-aos="fade-up">

            <div class="row justify-content-center">
                <h1>Categories</h1>
            </div>

            <!-- Web -->
            <div class="row mt-5 d-none d-lg-block">
                <div v-for="(category,index) in categories" :key="index" class="list-complete-item col-lg-4">
                    <router-link :to="{name: 'categoryProduct' , params: {q:encodeURI(category.name)}}">
                        <img class="list-complete-img" :src="category.image" alt>
                        <div class="overlay">
                            <div class="text">
                                {{category.name}}
                                <p>{{category.description}}</p>
                            </div>
                        </div>
                    </router-link>
                </div>
            </div>
            <!-- Mobile -->
            <div class="outer-row row mt-3 d-lg-none">
              <div class="icon-box col-12" v-for="(category,index) in categories" :key="index">
                  <div class="inner-row row">
                      <div class="image col-6" :class="index%2 == 0 ? 'order-first' : 'order-last'">
                            <router-link :to="{name: 'categoryProduct' , params: {q:encodeURI(category.name)}}">
                              <img :src="category.image" alt="">
                            </router-link>
                      </div>
                      <div class="content col-6">
                        <router-link :to="{name: 'categoryProduct' , params: {q:encodeURI(category.name)}}">
                            {{category.name}}
                            <p class="">{{category.description}}</p>
                        </router-link>
                      </div>
                  </div>
              </div>
            </div>
        </div>
    </section>
</template>

<script>

export default {
    name : 'checkout',
    components : {
        
    },
    data(){
        return{
            categories: [
                {
                    name: 'lifestyle & accessories',
                    image: 'img/charger-s5/img-1.jpeg',
                    description: 'You can never own too many accessories.'
                },
                {
                    name: 'rhythm & sounds',
                    image: 'img/airpods/img-4.jpeg',
                    description: 'Rhythm is the soul of life.'
                },
                {
                    name: 'wear & vesture',
                    image: 'img/t55-watch/img-3.jpeg',
                    description: 'If it’s not edible, it’s not food! If it’s not wearable, it’s not fashion!'
                },
                {
                    name: 'mood & lights',
                    image: 'img/touch-lamp/img-1.jpeg',
                    description: 'Sometimes it’s better to illuminate than to merely shine.'
                },
                {
                    name: 'design & decor',
                    image: 'img/star-light/img-5.jpeg',
                    description: 'Designs and Decorations are the fruits of life'
                },
            ]
        }
    },
    methods: {

    },
    created() {
        
    }
}
</script>

<style scoped lang='scss'>
#category {
  width: 100%;
  padding-bottom: 30px;
  height: auto;
  background-size: cover;
  position: relative;
  background: var(--bg-color);
}

#category:before {
  content: "";
  background: var(--bg-color);
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
}

#category .container {
  position: relative;
  padding-top: 24px;
  text-align: center;
}

#category h1 {
  margin: 0;
  font-size: 36px;
  font-weight: 700;
  line-height: 64px;
  color: black; 
  font-family: "Poppins", sans-serif;
  text-transform: uppercase;
}

#category h3 {
  color: rgba(255, 255, 255, 0.9);
  margin: 10px 0 0 0;
  font-size: 24px;
  text-align: left;
}

#category .list-complete-item {
    display: inline-block;
    padding-bottom: 15px;
}

#category .list-complete-item a {
    display: inline-block;
    position: relative;
}

#category .list-complete-item a img {
    display: block;
    width: 100%;
    height: auto;
}

#category .list-complete-item a .overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: .5s ease;
    background-color: rgba(0, 0, 0, 0.5);
}

#category .list-complete-item a:hover .overlay {
    opacity: 1;
}

#category .list-complete-item a .text {
    color: white;
    font-size: 22px;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-align: center;
    text-transform: capitalize;
    width: inherit;
}

#category .list-complete-item a .text p {
    font-size: 13px;
    color: rgba(255, 255, 255, 0.8);
    text-transform: initial;
}

#category .outer-row {
    margin: 0;
}

#category .icon-box {
    background: white;
    padding: 0;
    margin-bottom: 20px;
}

#category .icon-box .inner-row {
    margin: 0;
    box-shadow: 1px 6px 5px #aaaaaa;
}

#category .icon-box .image {
    padding: 0;
}

#category .icon-box .image img {
    width: 100%;
}

#category .icon-box .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
}

#category .icon-box .content a {
    color: black;
    text-decoration: none;
    text-transform: capitalize;
    font-size: 17px;
}

#category .icon-box .content p{
    color: rgba(0, 0, 0, 0.6);
    text-transform: none;
    font-size: 14px;
    line-height: 1.3;
}

@media (min-width: 1024px) {
  #category {
    background-attachment: fixed;
  }
}

@media (max-width: 768px) {
  #category {
    height: auto;
  }
  #category h1 {
    font-size: 20px;
    line-height: 36px;
  }
  #category .icon-box a .delete-btn {
      color: red;
  }
}
</style>