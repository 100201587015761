<template>
  <section id="promotion" class="promotion">
      <div class="">
          <!--:autoplay='{"delay": 2500,"disableOnInteraction": false}'-->
          <swiper :autoplay='{"delay": 5000,"disableOnInteraction": false}' :loop="true" class="mySwiper">
            <swiper-slide>
                <div class="promo-1">
                    <Banner1 />
                </div>
            </swiper-slide>
            <swiper-slide>
                <div class="promo-2">
                    <Banner2 />
                </div>
            </swiper-slide>
          </swiper>
      </div>
  </section>
</template>

<script>
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/swiper.scss';
import SwiperCore, {Autoplay} from 'swiper/core';
import Banner1 from './Promotions/Banner1'
import Banner2 from './Promotions/Banner2'

SwiperCore.use(Autoplay);

export default {
    name: 'promotionalBanner',
    components: {
        Swiper,
        SwiperSlide,
        Banner1,
        Banner2,
    },
}
</script>

<style scoped>
#promotion {
    background: var(--bg-color);
}

#promotion .promo-1 {
    background: linear-gradient(to top, rgba(0,0,0,0.6), rgba(0,0,0,0.4));
}

#promotion .promo-2 {
    background: linear-gradient(to top, rgba(0,0,0,0.4), rgba(0,0,0,0.6));
}
</style>