<template>
    <section id="banner1" class="banner1">
        <div class="conatiner">
            <router-link to="/product/7poYX6MQbvG5NqZGj4j2">
                <div class="row justify-content-center">
                    <div class="col-6 col-md-5 col-lg-4 content">
                        <div class="inner">
                            <h3>Revamp your life</h3>
                            <h5>Our Launch Sale</h5>
                            <p>
                                Get a 
                                <strong>FREE Trinique Smart Touch Lamp</strong>
                                with min order of 
                                <strong>&#8377;1499</strong>
                            </p>
                        </div>
                    </div>
                    <div class="col-5 col-sm-3 col-lg-2 image">
                        <img src="../../../public/img/touch-lamp\img-1.jpeg" alt="">
                    </div>
                </div>
            </router-link>
        </div>
    </section>
</template>

<script>
export default {
    name: 'Banner1'
}
</script>

<style scoped>
#banner1 {
    text-align: center;
}

#banner1 a {
    text-decoration: none;
    color: white;
}

#banner1 .content .inner {
    margin-top: 2rem;
}

#banner1 .content .inner h3 {
    font-weight: bold;
}

#banner1 .content .inner h5 {
    font-weight: bold;
}

#banner1 .image img {
    width: 90%;
    height: 90%;
    margin-top: 0.5rem;
}

@media (max-width: 768px){
    #banner1 .content .inner {
        margin-top: 0.4rem;
    }

    #banner1 .content .inner h3 {
        font-size: 20px;
    }

    #banner1 .content .inner h5 {
        font-size: 16px;
    }
    
    #banner1 .content .inner p {
        font-size: 13px;
        margin-bottom: 0.5rem;
    }
}

</style>