<template>
    <section id="mini-cart" class="mini-cart">
        <div class="modal fade" id="miniCart" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">My Bag</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="container" v-if="$store.getters.totalQuantity == 0">
                        <h5>Your Cart is Empty.</h5>
                        <h6>Please add item(s) in your cart.</h6>
                    </div>
                    <div class="modal-body" v-if="$store.getters.totalQuantity != 0">
                        <div class="outer-row row">
                            <div class="icon-box col-12" :key="index" v-for="(item,index) in $store.state.cart" :class="index == $store.state.cart.length-1 ? 'last-item' : ''">
                                <div class="inner-row row">
                                    <div class="image col-3 col-lg-2">
                                        <router-link :to="{name: 'productPage' , params: {id: item.productId}}">
                                            <img :src="item.productImage" alt="">
                                        </router-link>
                                    </div>
                                    <div class="content col-7 col-lg-9">
                                        <h3><router-link :to="{name: 'productPage' , params: {id: item.productId}}">{{item.productName}}</router-link></h3>
                                        <p class="details" v-if="item.productColor || item.productSize">
                                            <span class="color" v-if="item.productColor">Color: {{item.productColor}}</span>
                                            <span class="size" v-if="item.productSize">Size: {{item.productSize}}</span>
                                        </p>
                                        <p class="quantity">Quantity: {{item.productQuantity}}</p>
                                    </div>
                                    <div class="delete col-1">
                                        <a class="col-1" @click="$store.commit('removeFromCart',item)">
                                            <i class="fa fa-trash"></i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Continue Shopping</button>
                        <button v-if="$store.getters.totalQuantity != 0" type="button" class="btn checkout" @click="checkout">Checkout</button>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>

export default {
    name: 'MiniCart',
    watch: {
      //eslint-disable-next-line no-unused-vars
      '$route' (to,from) {
        window.$('#miniCart').modal('hide')
        // this.pageId = to
        // this.getData()
      }
    },
    methods: {
        checkout(){
            window.$('#miniCart').modal('hide')
            this.$router.push('/checkout')
        }
    }
}

</script>

<style scoped>
#mini-cart .modal-content {
    background: var(--bg-color);
}

#mini-cart .outer-row {
    margin: 0;
}

#mini-cart .icon-box {
    padding: 5px 15px 10px 15px;
    border-bottom: 1px solid #aaa;
}

#mini-cart .last-item {
    border-bottom: none;
}

#mini-cart .icon-box .inner-row {
    margin: 0;
}

#mini-cart .icon-box .image {
    padding: 0;
}

#mini-cart .icon-box .image img {
    width: 100%;
}

#mini-cart .icon-box .content h3 {
    font-size: 1rem;
}

#mini-cart .icon-box .content .details {
    margin-bottom: 0;
}

#mini-cart .icon-box .content .color {
    margin-right: 15px;
    text-transform: capitalize;
}

#mini-cart .icon-box .content a {
    color: black;
    text-decoration: none;
}

#mini-cart .icon-box a i:hover {
  color: red;
  cursor: pointer;
}

#mini-cart .checkout {
    background: #ffc451;
    border-color: #ffc451;
}

#mini-cart .checkout:hover {
    background: #efb441;
}

@media (max-width: 552px) {
    #mini-cart .icon-box {
        padding: 3px 0;
    }

    #mini-cart .icon-box .content {
        padding-right: 0;
    }
}
</style>