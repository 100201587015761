<template>
    <Navbar />
    <section class="admin">
        <div class="page-wrapper default-theme sidebar-bg bg1 toggled">
            <a id="show-sidebar" @click="closeMenu" class="btn btn-sm btn-dark" href="#">
                <i class="fa fa-bars"></i>
            </a>
            <nav id="sidebar" class="sidebar-wrapper">
                <div class="sidebar-content">
                    <!-- sidebar-brand  -->
                    <div class="sidebar-item sidebar-brand">
                        <div class="brand">
                            <router-link to="/">TRINIQUE</router-link>
                        </div>
                        <div id="close-sidebar" @click="closeMenu">
                            <i class="fa fa-times"></i>
                        </div>
                    </div>
                    <!-- sidebar-header  -->
                    <div class="sidebar-item sidebar-header">
                        <div class="user-pic">
                            <img class="img-responsive img-rounded" src="/img/user.png" alt="User picture">
                        </div>
                        <div class="user-info">
                            <span class="user-name">
                                <strong>{{profile.name}}</strong>
                            </span>
                            <span class="user-role"> {{email}} </span>
                            <span class="user-status">
                                <span v-if="uVerified"><i class="fa fa-circle"></i>Verified</span>
                                <span v-else><i class="fa fa-circle" style="color: red"></i>Unverified</span>
                            </span>
                        </div>
                    </div>
                    <!-- sidebar-search  
                    <div class="sidebar-item sidebar-search">
                        <div>
                            <div class="input-group">
                                <input type="text" class="form-control search-menu" placeholder="Search...">
                                <div class="input-group-append">
                                    <span class="input-group-text">
                                        <i class="fa fa-search" aria-hidden="true"></i>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    -->
                    <!-- sidebar-menu  -->
                    <div class=" sidebar-item sidebar-menu">
                        <ul>
                            <li class="header-menu">
                                <span>Menu</span>
                            </li>

                            <li>
                                <router-link @click="closeMenu" to="/profile/overview">
                                    <i class="fa fa-line-chart"></i>
                                    <span>Overview</span>
                                </router-link>
                            </li>
                            <li>
                                <router-link @click="closeMenu" to="/profile/orders">
                                    <i class="fa fa-shopping-cart"></i>
                                    <span>Orders</span>
                                </router-link>
                            </li>

                            <li>
                                <router-link @click="closeMenu" to="/profile/profile-setting">
                                    <i class="fa fa-user"></i>
                                    <span>Settings</span>
                                </router-link>
                            </li>
                            <li style="margin-bottom: 3.5rem">
                                <a href="#" @click="logout()">
                                    <i class="fa fa-power-off"></i>
                                    <span>Logout</span>
                                </a>
                            </li>
                        </ul>
                    </div>
                    <!-- sidebar-menu  -->
                </div>
        
            </nav>
            <!-- sidebar-content  -->
            <main class="">
                <router-view/>
            </main>
            <!-- page-content" -->
            <Footer />  
        </div>
    </section>
</template>

<script>
import {fb,db} from '../../firebase'

export default {
    name: 'admin',
    data(){
        return{
            uId: null,
            email: null,
            uVerified: false,
            profile: {
                name:null,
                email: null,
                phone:null,
                address:null,
                postCode:null,
                picture: null,
                orderCount: null,
            },
        }
    },
    components: {

    },
    methods: {
        closeMenu(){
            window.$(".page-wrapper").toggleClass("toggled");
        },
        logout(){
            fb.auth().signOut()
                .then(() => {
                    console.log(fb.auth().currentUser)
                    this.$store.commit('removeUserData')
                    this.$router.push('/')
                })
                .catch((err) => {
                    console.log(err)
                })
        },
        getProfile(){
            db.collection('profiles').doc(this.uId).get()
                .then((querySnapshot) => {
                    this.profile = querySnapshot.data()
                    this.$store.commit('addUser',this.profile)
                })
        },
    },
    created(){
        var user = fb.auth().currentUser
        this.email = user.email
        this.uId = user.uid
        this.uVerified = user.emailVerified
        this.getProfile()
    },
    mounted(){
        window.$(".page-wrapper").removeClass("toggled")
    }
}
</script>

<style scoped>
#show-sidebar{
    margin-top: 58px;
    z-index: 2;
}
#sidebar{
    margin-top: 68px;
}

#sidebar .brand {
    width: 100%;
}

#sidebar .brand a {
    font-size: 20px;
}

@media (max-width: 768px) {
    #show-sidebar{
        margin-top: 50px;
    }
    #sidebar{
        margin-top: 55px;
    }
}
</style>