<template>
    <transition name="fade">
        <div v-if="show" class="preloader">
            <div class="logo">
                <img src="../../public/img/logo.jpeg" alt="">
            </div>
        </div>
    </transition>
</template>

<script>
export default {
    name: 'preLoader',
    data(){
        return{
            show: true,
        }
    },
    mounted(){
		if(this.show) this.showToggle()
	},
	methods: {
		showToggle(){
			setTimeout(() => {
				this.show = false
			}, 700)
		}
	}
}
</script>

<style lang="scss" scoped>
.preloader{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: white;
    z-index: 9999;
    .logo img {
        height: 12.5rem;
        width: 12.5rem;
        background: url('../../public/img/logo.jpeg');
        background-repeat: no-repeat;
        animation: zoom-in 2s ease-out infinite;
    }
}
.fade-enter-active, .fade-leave-active {
    transition: opacity 1s;
    .logo {
        animation-name: scale-out;
        animation-duration: 1s;
    }
}
.fade-enter, .fade-leave-to {
    opacity: 0
}
@keyframes zoom-in {
    0% {
        transform:scale(1,1);
    }
    50%{
        transform:scale(1.5,1.5);
    }
    100%{
        transform:scasle(1,1);
    }
}    


</style>