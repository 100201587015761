<template>
    <section id="profile" class="profile">
        <div class="container">
            <div class="details">
                <img src="../../../public/img/user.png" alt="" class="profile-pic">
                <h1 class="heading">{{ $store.state.profile.name }}</h1>
                
                <p class="mail"><i class="fa fa-envelope" aria-hidden="true"></i>{{ email }}</p>
                
                <div class="stats">
                    <div class="col-6">
                        <h4>{{$store.state.profile.orderCount}}</h4>
                        <p>Orders</p>
                    </div>
                    <div class="col-6" v-if="!uVerified">
                        <h4>Pending</h4>
                        <p>Verification</p>
                    </div>
                    <div class="col-6" v-if="uVerified">
                        <h4>Done</h4>
                        <p>Verification</p>
                    </div>
                </div>
            </div>
            <div class="row mt-5 justify-content-center">
                <div class="col-lg-8">
                    <div class="address-details">
                        <div class="header">
                            Profile Settings
                            <button @click="edit = !edit" class="edit-btn">Edit</button>
                        </div>
                        <div class="p-2 pt-4 p-lg-4">
                            <p class="font-italic mb-2" v-if="!uVerified">
                                Please Verify E-Mail to order products.
                                <button @click="verifyUser" class="verify-btn btn">Verify E-Mail</button>
                            </p>
                            <div class="user-form">
                                <!--
                                <div class="form-group">
                                    <label for="image">Profile Picture</label>
                                    <input type="file" id="image"  :disabled="!edit" @change="uploadImage" class="form-control">
                                </div>
                                -->
                                <div class="form-group">
                                    <label for="name">Full Name</label>
                                    <input type="text" id="name" :disabled="!edit" v-model="name" placeholder="Full Name" class="form-control">
                                </div>
                                <div class="form-group">
                                    <label for="phone">Phone Number (+91)</label>
                                    <input type="text" id="phone" :disabled="!edit" v-model="phone"  placeholder="Phone" class="form-control">
                                </div>
                                <div class="form-group">
                                    <label for="address">Complete Address</label>
                                    <input type="text" id="address" :disabled="!edit" v-model="address" placeholder="Complete Address" class="form-control">
                                </div>
                                <div class="form-group">
                                    <label for="postCode">Postcode</label>
                                    <input type="text" id="postCode" :disabled="!edit" v-model="postCode"  placeholder="Postcode" class="form-control">
                                </div>
                                <div class="form-group mt-4 pt-1">
                                    <input type="submit" @click="updateProfile" :disabled="!edit" value="Save Changes" class="btn btn-primary w-100">
                                </div>
                                <div class="form-group">
                                    <input type="button" @click="resetPassword" value="Reset Password" class="btn btn-success w-100">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import {fb,db} from '../../firebase'

export default {
    name: 'profile',
    components: {
        
    },
    data(){
        return{
            uVerified: false,
            email: null,
            profile: {
                name:null,
                email: null,
                phone:null,
                address:null,
                postCode:null,
                picprevRoute: null,
                orderCount: null,
            },
            name: null,
            phone:null,
            address:null,
            postCode:null,
            user: fb.auth().currentUser,
            prevRoute: null,
            edit: false ,
        }
    },
    beforeRouteEnter(to, from, next) {
        next((vm) => {
            vm.prevRoute = from;
        });
    },
    methods: {
        // deleteImage(img,index){
        //     var image = fb.storage().refFromURL(img)

        //     this.products.images.splice(index,1)
        //     image.delete()
        //         .then(() => {
        //             console.log('Image Deleted')
        //         })
        //         .catch((error) => {
        //             console.log(error)
        //         })
        // },
        // uploadImage(e){
        //     if(e.target.files[0]){

        //         var file = e.target.files[0]
        //         console.log(file.name)
                
        //         var storageRef = fb.storage().ref().child(file.name)
        //         const metadata = { contentType: 'image/jpeg' };
    
        //         var uploadTask = storageRef.put(file,metadata)
    
        //         // eslint-disable-next-line no-unused-vars
        //         uploadTask.on('state_changed',(snapshot) => {
        //             // While uploading image this function runs
        //             // eslint-disable-next-line no-unused-vars
        //         },(error) => {
        //             console.log(error)
        //             // For error this function runs
        //         } ,() => {
        //             uploadTask.snapshot.ref.getDownloadURL()
        //                 .then((downloadURL) => {
        //                     this.profile.picture = downloadURL
        //                 })
        //         })
        //     }
        // },
        userUnverified(){
            if(!this.uVerified){
                window.Toast.fire({
                    icon: 'warning',
                    title: 'Please Verify E-Mail to order products.'
                })
            }
        },
        verifyUser(){
            fb.auth().currentUser.sendEmailVerification()
                .then(() => {
                    window.Toast.fire({
                        icon: 'success',
                        title: 'Verification E-Mail sent'
                    })
                }).catch((error) => {
                    console.log(error)
                    window.Toast.fire({
                        icon: 'error',
                        title: 'Something went wrong.Please try again later.'
                    })
                })
        },
        incompleteInfo(){
        if(!this.profile.phone && !this.profile.address && !this.profile.postCode ){
            window.Toast.fire({
                icon: 'warning',
                title: 'Please Enter Details to Order Products.'
            })
        }
        },
        resetPassword(){
        fb.auth().sendPasswordResetEmail(this.user.email)
            .then(() => {
                window.Toast.fire({
                    icon: 'success',
                    title: 'Reset link sent to your e-mail'
                })
                fb.auth().signOut()
                    .then(() => {
                        this.$store.commit('removeUserData')
                        this.$router.replace('/')
                    })
                    .catch((err) => {
                        console.log(err)
                    })
                
            })
            .catch((error) => {
                console.log(error);
            })
        },
        updateProfile(){
            this.edit = false
            this.profile.name = this.name
            this.profile.phone = this.phone
            this.profile.address = this.address
            this.profile.postCode = this.postCode
            db.collection('profiles').doc(this.user.uid).update(this.profile)
            .then(() => {
                window.Toast.fire({
                    icon: 'success',
                    title: 'Changes Saved'
                })
                this.$store.commit('addUser',this.profile)
                this.getProfile(true)
            }).catch((error) => {
                console.log(error)
                window.Toast.fire({
                    icon: 'error',
                    title: 'Something went wrong.Please try again later.'
                })
                this.getProfile(true)
            })
        },
        getProfile(fromUpdate){
            db.collection('profiles').doc(this.user.uid).get()
                .then((querySnapshot) => {
                    this.profile = querySnapshot.data()
                    this.$store.commit('addUser',this.profile)
                    this.name = this.profile.name
                    this.phone = this.profile.phone
                    this.address = this.profile.address
                    this.postCode = this.profile.postCode

                    if (this.prevRoute.path == "/checkout"){
                        this.edit = true
                    }
                    
                    if (!fromUpdate){
                        this.incompleteInfo()
                    }
                }).catch((error) => {
                    console.log(error)
                })
        },
    },
    created(){
        this.uVerified = this.user.emailVerified
        this.email = this.user.email
        this.getProfile(false)
    }
}
</script>

<style scoped>
#profile {
    background: linear-gradient(to top, #ffdd00, #fbb034);
}

#profile .container {
  position: relative;
  padding-top: 74px;
  color: white;
  min-height: 60vh;
  text-align: center;
}

#profile .details .profile-pic {
  height: 6rem;
  width: 6rem;
  object-fit: center;
  border-radius: 50%;
  border: 2px solid #fff;
  margin-top: 2rem;
}

#profile .details .heading {
  font-weight: 400;
  font-size: 1.7rem;
  margin: 1rem 0;
}

#profile .details .mail {
  display: inline-block;
  font-size: 1rem;
}

#profile .details .mail i {
    margin-right: 0.5rem;
}

.stats {
  display: flex;
  margin-top: 4rem;
  font-size: 1.5rem;
}

#profile .address-details {
    background: white;
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.4);
    border-radius: 2%;
    margin-bottom: 20px;
    color: black;
}

#profile .address-details .header {
    padding: 1rem 1.5rem;
    font-weight: 700;
    text-transform: uppercase;
    border-radius: 1rem;
    background-color: #f8f9fa;
}

#profile .address-details .edit-btn {
    float: right;
    padding: 0.5rem 1rem;
    background: #343a40;
    border-radius: 50rem;
    text-decoration: none;
    color: white;
    font-weight: 400;
    transform: translateY(-10px);
    border: none;
}

#profile .address-details .verify-btn {
    padding: 0 0.5rem;
    border-radius: 50rem;
    background: yellow;
    border: 1px solid black;
    font-size: 0.9rem;
}

#profile .address-details .user-form {
    text-align: left;
}

</style>